import React from 'react';
import { Icon } from './iconRender';

export default ({
  className = '',
  name,
  addressIcon = 'faMapMarkedAlt',
  address,
  addressPrefix = '',
  phoneIcon = 'faPhone',
  phone1,
  phone1Prefix = '電話：',
  phone2,
  phone2Prefix = '国內客户转驳专线：',
  faxIcon = 'faFax',
  fax,
  faxPrefix = '傳真：',
  emailIcon = 'faEnvelope',
  email,
  emailPrefix = '電郵：'
}) => (
  <ul className={`fa-ul mt-3 ml-3 mt-md-0 ml-md-5 ${className}`}>
    {name && (
      <li>
        <b>{name}</b>
      </li>
    )}
    {address && (
      <li>
        {addressIcon && (
          <span className={'fa-li'}>
            <Icon icon={addressIcon} />
          </span>
        )}

        <p className={'mb-0'} style={{ whiteSpace: 'pre-wrap' }}>
          <span>{addressPrefix}</span>
          {address}
        </p>
      </li>
    )}
    {phone1 && (
      <li>
        {phoneIcon && (
          <span className={'fa-li'}>
            <Icon icon={phoneIcon} />
          </span>
        )}
        <span>
          {phone1Prefix}
          {phone1}
        </span>
      </li>
    )}
    {phone2 && (
      <li>
        {phone2Prefix}
        {phone2}
      </li>
    )}
    {fax && (
      <li>
        {faxIcon && (
          <span className={'fa-li'}>
            <Icon icon={faxIcon} />
          </span>
        )}
        <span>
          {faxPrefix}
          {fax}
        </span>
      </li>
    )}
    {email && (
      <li>
        {emailIcon && (
          <span className={'fa-li'}>
            <Icon icon={emailIcon} />
          </span>
        )}
        <span>
          {emailPrefix}
          <a href={`mailto:${email}`}>{email}</a>
        </span>
      </li>
    )}
  </ul>
);
