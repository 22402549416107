import React, { Fragment } from 'react';
import { ComponentRender } from '../../components/components';

export default ({ body = [] }) => (
  <Fragment>
    {body.map(({ name, config }, i) => (
      <ComponentRender key={i} tag={name} config={JSON.parse(config)} />
    ))}
  </Fragment>
);
