import { client } from '../../../shared/apollo';
import gql from 'graphql-tag';
import { errorParser } from '../../../shared/errorParser';

export default async ({ checkoutId, couponId, couponCode }) => {
  if (couponCode) {
    const { data: { node } = {} } = await client.query({
      query: gql`
        query($handle: String) {
          node(handle: $handle) {
            id
            __typename
          }
        }
      `,
      variables: {
        handle: couponCode
      }
    });
    const { id, __typename } = node || {};
    if (__typename !== 'Coupon') throw new Error('Error: Invalid Coupon');
    couponId = id;
  }

  const {
    data: { checkoutCouponSet }
  } = await client.mutate({
    mutation: gql`
      mutation($id: ID!, $couponId: ID!) {
        checkoutCouponSet(id: $id, couponId: $couponId) {
          id
          amount
          totalFee
          coupons {
            id
            coupon {
              id
              handle
              name
            }
          }
        }
      }
    `,
    variables: {
      id: checkoutId,
      couponId
    }
  });
  return checkoutCouponSet;
};
