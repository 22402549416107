import { client } from '../../../shared/apollo';
import gql from 'graphql-tag';
import { errorParser } from '../../../shared/errorParser';

const checkoutSet = async ({ checkoutId, input }) => {
  const {
    shopId,
    userId,
    shippingAddress,
    billingAddress,
    deviceId,
    attributes
  } = input;
  try {
    const { data: { checkoutSet } = {} } = await client.mutate({
      mutation: gql`
        mutation($id: ID, $input: CheckoutInput!) {
          checkoutSet(id: $id, input: $input) {
            id
            user {
              id
            }
            attributes {
              key
              value
            }
            shippingAddress {
              person
              email
              tel
              lines
              country
            }
            billingAddress {
              person
              email
              tel
              lines
              country
            }
            items {
              id
              productVariant {
                id
                quantity
              }
              quantity
            }
            shippingFee
            taxFee
            discountedPrice
          }
        }
      `,
      variables: {
        id: checkoutId,
        input: {
          shopId,
          userId,
          shippingAddress,
          billingAddress,
          deviceId,
          attributes
        }
      }
    });

    if (!checkoutSet) {
      //error handle
    }
    return checkoutSet;
  } catch (e) {
    throw new Error(errorParser(e.message));
  }
};
export default checkoutSet;
