import gql from 'graphql-tag';
import { client } from '../../../shared/apollo';
import {errorParser} from "../../../shared/errorParser";

const checkoutProcess = async ({ checkoutId, input }) => {
  const { provider = 'MANUAL', token } = input;
  try {
    const {
      data: { result }
    } = await client.mutate({
      mutation: gql`
        mutation($id: ID!, $input: CheckoutPaymentInput!) {
          result: checkoutProcess(id: $id, input: $input) {
            id
            status
            discountedPrice
            shippingFee
            taxFee
            transactionId
            user {
              id
            }
            order {
              id
              items {
                id
                quantity
                unitPrice {
                  locale
                  amount
                }
                productVariant {
                  id
                  product {
                    id
                    name
                  }
                  attributes {
                    key
                    value
                  }
                }
              }
              subtotal {
                locale
                amount
              }
              total {
                locale
                amount
              }
            }
            items {
              id
              description
              remarks
            }
          }
        }
      `,
      variables: {
        id: checkoutId,
        input: {
          provider,
          token
        }
      }
    });
    return result;
  } catch (e) {
    throw new Error(errorParser(e.message));
  }
};

export default checkoutProcess;
