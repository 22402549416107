import React from 'react';
import { FormGroup, Input, Label, FormText } from 'reactstrap';

export default ({
  value,
  name,
  maxlength = 50,
  pattern,
  placeholder,
  description,
  required = true,
  onChange
}) => (
  <FormGroup>
    <Label>
      {name} {required && '*'}
    </Label>
    <Input
      value={value}
      name={name}
      required={required}
      placeholder={placeholder}
      onChange={onChange}
      pattern={pattern}
      maxlength={maxlength}
    />
    <FormText>{description}</FormText>
  </FormGroup>
);
