import translate from './translate';
import * as Sentry from "@sentry/react";

export const errorParser = (e, defaultError = 'Error: general') => {
  let msg = e.message || e;
  msg = msg.replace(/^GraphQL error:/, '').trim();
  if (!msg.match(/^Error: /)) msg = `Error: ${msg}`;
  console.log(msg);
  Sentry.captureException(e);
  return translate[msg] || (defaultError ? translate[defaultError] : msg);
};
