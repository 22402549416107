import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './style.scss';

export default ({
  className,
  currentPage = 14,
  maxPage = 15,
  display = 5,
  onClick
}) => {
  let displayHalf = ~~(display / 2);
  return (
    <Pagination className={`styled-pagination ${className}`}>
      {currentPage - displayHalf > 1 ? (
        <PaginationItem>
          <PaginationLink onClick={() => onClick(1)}>1...</PaginationLink>
        </PaginationItem>
      ) : null}

      {Array(display)
        .fill(null)
        .map((_, i) => {
          if (currentPage + displayHalf > maxPage)
            return maxPage - display + i + 1;
          return Math.max(currentPage - displayHalf, 1) + i;
        })
        .filter(i => i >= 1 && i <= maxPage)
        .map(i => (
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink onClick={() => onClick(i)}>{i}</PaginationLink>
          </PaginationItem>
        ))}
      {currentPage + displayHalf < maxPage ? (
        <PaginationItem>
          <PaginationLink onClick={() => onClick(maxPage)}>
            ...{maxPage}
          </PaginationLink>
        </PaginationItem>
      ) : null}
    </Pagination>
  );
};
