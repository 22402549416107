import React, { Fragment } from 'react';
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Loading from '../../components/loading';
import { client } from '../../shared/apollo';
import translate from '../../shared/translate';
import { errorParser } from '../../shared/errorParser';
import { Helmet } from 'react-helmet';
import { RESET_PASSWORD } from './query';
import jwt_code from 'jwt-decode';

export default ({ history, location: { search } = {} }) => {
  const token = new URLSearchParams(search).get('token');
  let identity = undefined;
  try {
    const decode = jwt_code(token);
    identity = decode.identity;
  } catch (e) {}

  if (!identity) {
    toast.error(translate['page_not_found']);
    history.replace('/');
    return null;
  }

  const submit = async ({ password, password2 }, { setSubmitting }) => {
    try {
      if (password !== password2)
        throw new Error('Error: confirmation password not correct');

      await client.mutate({
        mutation: RESET_PASSWORD,
        variables: { token, secret: password }
      });
      toast.success(translate['submit_success']);
      history.replace('/login');
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {translate['reset_password']} - {translate['meta.main.title']}
        </title>
      </Helmet>
      <Container className={'py-5'}>
        <Row>
          <div className={'col-md-6 offset-md-3'}>
            <Formik onSubmit={submit}>
              {({ values, handleSubmit, handleChange, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <h1
                    className={'text-center py-3'}
                    style={{ fontSize: 30, fontWeight: 'bold' }}
                  >
                    {translate['reset_password']}
                  </h1>
                  <FormGroup>
                    <Label htmlFor={'email'}>
                      {translate['email_address']}
                    </Label>
                    <Input defaultValue={identity} disabled />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor={'password'}>
                      {translate['new_password']}
                    </Label>
                    <Input
                      required={true}
                      value={values.password}
                      name={'password'}
                      type={'password'}
                      placeholder={translate['new_password']}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor={'password'}>
                      {translate['confirm_password']}
                    </Label>
                    <Input
                      required={true}
                      value={values.password2}
                      name={'password2'}
                      type={'password'}
                      placeholder={translate['confirm_password']}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  {isSubmitting ? (
                    <Loading />
                  ) : (
                    <FormGroup className={'text-center'}>
                      <Button
                        type={'submit'}
                        color={'primary'}
                        className={'px-5'}
                      >
                        {translate['submit']}
                      </Button>
                    </FormGroup>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </Row>
      </Container>
    </Fragment>
  );
};
