import React from 'react';
import { Container, Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import HeaderNav from './headerNav';

export default ({ items = [], onClick }) => (
  <Navbar expand={'md'} light className={'d-none d-md-flex px-3 pt-2 pb-5'}>
    <Container>
      <Nav className={'w-100 justify-content-between'}>
        {items.map(({ text, href, active }, i) => (
          <NavItem key={i}>
            <NavLink
              href={href}
              active={active}
              onClick={e => {
                e.preventDefault();
                onClick && onClick(href);
              }}
            >
              {text}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </Container>
  </Navbar>
);

HeaderNav.propTypes = {
  topBarProps: PropTypes.any,
  pages: PropTypes.array,
  imageLink: PropTypes.array,
  onMenuClick: PropTypes.func
};
