import React, { Fragment } from 'react';
import StyledTitle from '../../components/styledTitle';
import { Row, Button } from 'reactstrap';
import { auto2sc } from '../../shared/translate/tc2sc';
import translate from '../../shared/translate';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Image from 'react-graceful-image';

export default ({ services }) => {
  let groupedServices = _.groupBy(services, 'group');
  return (
    <div>
      {Object.keys(groupedServices).map((group, i) => (
        <Fragment key={i}>
          <div className={'mb-3 mt-5 mt-md-0'}>
            <StyledTitle type={'dark'} text={auto2sc(group)} />
          </div>
          {groupedServices[group].map((props, i) => (
            <ServiceListItem key={i} {...props} />
          ))}
        </Fragment>
      ))}
    </div>
  );
};

export const ServiceListItem = ({
  name,
  description,
  images: [image] = [],
  price,
  id
}) => (
  <div>
    {!!image && (
      <Row className={'justify-content-sm-start justify-content-center'}>
        <div className={'col-md-2 col-sm-4 col-8 mb-3'}>
          <Image
            className={'img-fluid'}
            src={image}
            alt={name}
            placeholderColor={'transparent'}
          />
        </div>
      </Row>
    )}
    <Row className={'mb-md-3 mb-5 justify-content-center'}>
      <div className={'col-sm'}>
        <h6>{auto2sc(name)}</h6>
        <p>{auto2sc(description)}</p>
      </div>
      <div className={'col-sm-auto text-center'}>
        <p className={'mb-1'}>
          {price.toLocaleString(undefined, {
            currency: 'HKD',
            style: 'currency'
          })}
        </p>
        <Link to={`/checkout/${id}`}>
          <Button className={'btn-sm'} outline={true}>
            {translate['purchase_now']}
          </Button>
        </Link>
      </div>
    </Row>
  </div>
);
