import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faSpinner
} from '@fortawesome/pro-regular-svg-icons';

export default class Loading extends Component {
  constructor(props) {
      super(props);
      this.state = {}
  }

  render() {
    const {
      color= ""
    } = this.props;
    return (
      <div className={`text-center mt-5 mb-5`}>
        <FontAwesomeIcon icon={faSpinner} spin size="2x" color={color}/>
      </div>
    );
  }
}