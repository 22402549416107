import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

export default class ErrorBoundary extends Component {
  state = {
    error: undefined
  };

  static getDerivedStateFromError(error) {
    Sentry.captureException(error);
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        this.props.fallback || (
          <div style={{ marginTop: 40 }}>
            <h5 style={{ textAlign: 'center' }}>
              {(error || {}).message || error || 'Error...'}
            </h5>
          </div>
        )
      );
    }
    return this.props.children;
  }
}
