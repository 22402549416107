import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import StyledNav from '../../components/styledNav';

import Profile from './profile';
import ChangePassword from './changePassword';
import Addresses from './addresses';
import Orders from './orders';
import OrderView from './orders/view';
import Events from './events';
import EventView from './events/view';
import translate from '../../shared/translate';
import { Helmet } from 'react-helmet';

const routes = [
  {
    title: translate['menu_profile'],
    path: '/profile',
    component: Profile,
    exact: true
  },
  {
    title: translate['menu_change_password'],
    path: '/profile/change-password',
    component: ChangePassword,
    exact: true
  },
  {
    title: translate['menu_addresses'],
    path: '/profile/addresses',
    component: Addresses,
    exact: true
  },
  {
    title: translate['menu_orders'],
    path: '/profile/orders',
    component: Orders,
    exact: true
  },
  {
    path: '/profile/orders/:id',
    component: OrderView,
    hidden: true,
    exact: false
  },
  {
    title: translate['menu_events'],
    path: '/profile/events',
    component: Events,
    exact: true
  },
  {
    path: '/profile/events/:id',
    component: EventView,
    hidden: true,
    exact: false
  }
];

export default ({ history, location: { pathname } }) => {
  if (!localStorage.getItem('token')) {
    history.replace('/login', { referer: history.location.pathname });
    return null;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {translate['menu_member']} - {translate['meta.main.title']}
        </title>
      </Helmet>
      <Container className={'py-5'}>
        <StyledNav
          items={routes
            .filter(item => !item.hidden)
            .map(item => ({
              ...item,
              href: item.path,
              active: item.path === pathname
            }))}
        />
        <Switch>
          {routes.map(({ exact, path, component }, i) => (
            <Route exact={exact} path={path} component={component} key={i} />
          ))}
        </Switch>
      </Container>
    </Fragment>
  );
};
