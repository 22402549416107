import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ContactBlock from '../../../../components/contactBlock';
import moment from 'moment';
import { Icon } from '../../../../components/iconRender';
import './style.scss';

export default ({
  onClick,
  disabled = false,
  selected = false,
  name = '',
  availableSlots = [
    {
      startedAt: '2019-07-15 00:00:00',
      startedThru: '2019-07-15 00:30:00'
    },
    {
      startedAt: '2019-07-15 00:30:00',
      startedThru: '2019-07-15 01:00:00'
    }
  ],
  address: {
    tel = '(852) 98765431',
    email = 'info@test.com',
    lines = ['', '', '']
  } = {}
}) => {
  if (availableSlots.length === 0) return null;

  let sortedSlots = availableSlots.sort((slotA, slotB) =>
    moment(slotA.startedAt).isAfter(slotB.startedAt) ? 1 : -1
  );

  let { startedAt: startTime = '' } = sortedSlots[0];
  let { startedThru: endTime = '' } = sortedSlots[sortedSlots.length - 1];

  return (
    <Card
      className={`venue-card mb-2 ${selected ? 'selected' : ''}`}
      onClick={() => {
        if (disabled) return;
        onClick && onClick();
      }}
    >
      <CardBody className={'col'}>
        <Row>
          <ContactBlock
            className={'mb-0 col-sm ml-5'}
            name={name}
            phone1={tel}
            email={email}
            address={lines.join('\n')}
          />
          <Col
            className={'text-sm-right text-left col-sm-auto mt-3 mt-sm-0 ml-4'}
          >
            <dt>{moment(startTime).format('MMMM D, YYYY')}</dt>
            <dd>{moment(startTime).format('h:mm a')}</dd>
            <Icon icon={'faLongArrowDown'} />
            <dd>{moment(endTime).format('h:mm a')}</dd>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
