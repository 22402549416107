import { client } from '../../../shared/apollo';
import gql from 'graphql-tag';
import {errorParser} from "../../../shared/errorParser";

export const GET_CHECKOUT = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Checkout {
        status
        payment {
          token
        }
        
        shippingAddress {
          person
          email
          tel
          lines
          country
        }
        billingAddress {
          person
          email
          tel
          lines
          country
        }
        items {
          id
          productVariant {
            id
            product {
              id
              name
              images
            }
            attributes {
              key
              value
            }
            price
            quantity
          }
          quantity
          unitPrice
        }
        shop {
          id
        }
        attributes {
          key
          value
        }
        shippingFee
        taxFee
        discountedPrice
        updatedAt
      }
    }
  }
`;

const checkoutGet = async id => {
  try {
    const { data: { node } = {} } = await client.query({
      query: GET_CHECKOUT,
      variables: {
        id
      }
    });


    return node;
  } catch (e) {
    throw new Error(errorParser(e.message));
  }
};

export default checkoutGet;
