import React from 'react';
import { Button } from 'reactstrap';

export default ({ translate, onChange }) => {
  let { _language, _props } = translate;
  return (
    <Button
      onClick={() => {
        const keys = Object.keys(_props);
        const id = keys.findIndex(lang => lang === _language);
        let nextId = id + 1;
        if (nextId >= keys.length) nextId = 0;
        onChange && onChange(keys[nextId]);
      }}
      color={'link'}
      size={'sm'}
      outline={true}
    >
      {translate[_language]}
    </Button>
  );
};
