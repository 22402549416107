import React, { Component, Fragment } from 'react';
import AddressesList from './_list';
import gql from 'graphql-tag';
import { client } from '../../../shared/apollo';
import Loading from '../../../components/loading';
import { toast } from 'react-toastify';
import AddressForm from './_addressForm';
import translate from '../../../shared/translate';
import { errorParser } from '../../../shared/errorParser';
import { Helmet } from 'react-helmet';

const ME = gql`
  query {
    me {
      id
      credentials {
        id
        identity
      }
      addresses {
        id
        person
        tel
        email
        lines
        country
      }
    }
  }
`;

const ADDRESS_SET = gql`
  mutation($id: ID, $input: AddressInput!) {
    addressSet(id: $id, input: $input) {
      id
      person
      tel
      email
      lines
      country
    }
  }
`;

const ADDRESS_UNSET = gql`
  mutation($id: ID!) {
    addressUnset(id: $id) {
      id
    }
  }
`;

export default class Addresses extends Component {
  state = {
    userId: '',
    loading: true,
    selected: -1,
    selectedAddress: null,
    addresses: []
  };

  async componentDidMount() {
    try {
      const {
        data: {
          me: { id, addresses }
        }
      } = await client.query({
        query: ME,
        fetchPolicy: 'no-cache'
      });
      this.setState({ userId: id, addresses });
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      this.setState({ loading: false });
    }
  }

  onSelect = i => {
    let { selected, addresses } = this.state;
    let toggleSelected = selected === i ? -1 : i;
    this.setState({
      selected: toggleSelected,
      selectedAddress:
        toggleSelected === addresses.length ? {} : addresses[toggleSelected]
    });
  };

  addressSet = async ({ id, userId, person, tel, email, lines, country }) => {
    let {
      data: { addressSet }
    } = await client.mutate({
      mutation: ADDRESS_SET,
      variables: {
        id,
        input: {
          userId,
          person,
          tel,
          email,
          lines,
          country
        }
      }
    });
    return addressSet;
  };

  addressUnset = async ({ id }) => {
    const {
      data: { addressUnset }
    } = await client.mutate({
      mutation: ADDRESS_UNSET,
      variables: {
        id
      }
    });
    return addressUnset;
  };

  submitUnset = async ({ id }) => {
    let { addresses } = this.state;
    try {
      await this.addressUnset({ id });
      this.setState({
        selected: -1,
        selectedAddress: null,
        addresses: addresses.filter(address => address.id !== id)
      });
      toast.success(translate['delete_success']);
    } catch (e) {
      toast.error(errorParser(e));
    }
  };
  submitUpdate = async (values, { setSubmitting }) => {
    let { addresses } = this.state;

    try {
      let address = await this.addressSet({
        ...values,
        lines: [values.line_1, values.line_2, values.line_3].filter(_ => _)
      });

      let target = addresses.find(_address => _address.id === address.id);
      Object.assign(target, address);

      this.setState({ addresses: [...addresses] });
      toast.success(translate['update_success']);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
    }
  };
  submitCreate = async (values, { setSubmitting }) => {
    let { userId, addresses } = this.state;
    try {
      let address = await this.addressSet({
        ...values,
        lines: [values.line_1, values.line_2, values.line_3].filter(_ => _),
        userId
      });
      this.setState({
        addresses: [address, ...addresses],
        selected: 0,
        selectedAddress: address
      });
      toast.success(translate['create_success']);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    let { loading, addresses, selected } = this.state;
    if (loading) return <Loading />;
    return (
      <Fragment>
        <Helmet>
          <title>
            {translate['menu_addresses']} - {translate['meta.main.title']}
          </title>
        </Helmet>
        <AddressesList
          addresses={addresses}
          selected={selected}
          onSelect={this.onSelect}
        />
        {this.renderUpdateForm()}
        {this.renderCreateForm()}
      </Fragment>
    );
  }

  renderUpdateForm() {
    let { selected, selectedAddress, addresses } = this.state;
    if (selected === -1 || selected === addresses.length) return null;
    let lines = selectedAddress.lines || [];
    return (
      <div className={'mt-5'}>
        <AddressForm
          onSubmit={this.submitUpdate}
          onCancel={() => this.submitUnset(selectedAddress)}
          initialValues={{
            ...selectedAddress,
            line_1: lines[0],
            line_2: lines[1],
            line_3: lines[2]
          }}
        />
      </div>
    );
  }
  renderCreateForm() {
    let { selected, addresses } = this.state;
    if (selected !== addresses.length) return null;
    return (
      <div className={'mt-5'}>
        <AddressForm
          onSubmit={this.submitCreate}
          initialValues={{
            email: '',
            line_1: '',
            line_2: '',
            line_3: '',
            country: 'HKG'
          }}
        />
      </div>
    );
  }
}
