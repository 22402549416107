import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Button } from 'reactstrap';
import Loading from '../../components/loading';
import QRCode from 'qrcode.react';
import translate from '../../shared/translate';

const CHECKOUT_GET = gql`
  query($id: ID!) {
    node(id: $id) {
      id
      ... on Checkout {
        order {
          id
          items {
            id
            quantity
            unitPrice {
              locale
              amount
            }
            productVariant {
              id
              product {
                id
                name
              }
              attributes {
                key
                value
              }
            }
          }
          subtotal {
            locale
            amount
          }
          total {
            locale
            amount
          }
        }
        discountedPrice
        shippingFee
        taxFee
        transactionId
        status
      }
    }
  }
`;

export default class QRCodePaymentWidget extends Component {
  render() {
    let { token, onSuccess, checkoutId, onReturn } = this.props;

    return (
      <Fragment>
        <h4>{translate['please_scan_this_qrcode']}</h4>
        <hr />
        <Query
          query={CHECKOUT_GET}
          variables={{ id: checkoutId }}
          fetchPolicy={'network-only'}
          pollInterval={2000}
        >
          {({ loading, data }) => {
            if (this.resolved || loading) return <Loading />;
            const { node } = data || {};
            const { status } = node || {};
            if (status === 'COMPLETED') {
              this.resolved = true;
              onSuccess && onSuccess(node);
              return <Loading />;
            }
            try {
              if (token.match(/^https?:\/\//))
                return <img src={token} alt={'QR Code'} width={256} />;
              else return <QRCode value={token} size={256} />;
            } catch (e) {
              return (
                <Fragment>
                  <p className={'lead'}>{translate['payment_error_msg']}</p>
                  <Button outline={true} className={'px-3'} onClick={onReturn}>
                    <span>{translate['return']}</span>
                  </Button>
                </Fragment>
              );
            }
          }}
        </Query>
      </Fragment>
    );
  }
}
QRCodePaymentWidget.propTypes = {
  token: PropTypes.string,
  onSuccess: PropTypes.func,
  onReturn: PropTypes.func,
  checkoutId: PropTypes.string
};
