import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Loading from '../../components/loading';
import StyledPagination from '../../components/styledPagination';
import { Container } from 'reactstrap';
import PromotionList from './_promotionList';
import translate from '../../shared/translate';
import { Helmet } from 'react-helmet';

const GET_QUERY = gql`
  query($skip: Int, $limit: Int) {
    node: shopByHostname {
      id
      customPagesCount(
        otherFilter: { groups: ["hc_promotion"], active: true, display: true }
      )
      customPages(
        otherFilter: { groups: ["hc_promotion"], active: true, display: true }
        offset: { skip: $skip, limit: $limit }
      ) {
        id
        name
        group
        href
        createdAt
      }
    }
  }
`;

export default class extends Component {
  state = {
    skip: 0,
    limit: 5
  };

  onPaginationClick = async page => {
    let { limit } = this.state;
    this.setState({ skip: limit * (page - 1) });
  };

  render() {
    const { history } = this.props;
    const { skip, limit } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>
            {translate['menu_promotion']} - {translate['meta.main.title']}
          </title>
        </Helmet>
        <Container className={'container-fluid my-3'}>
          <Query query={GET_QUERY} variables={{ skip, limit }}>
            {({ loading, data }) => {
              if (loading) return <Loading />;
              const { node } = data || {};
              const { customPagesCount = 0, customPages = [] } = node || {};
              let maxPage = Math.ceil(customPagesCount / limit);
              if (customPagesCount === 0)
                return (
                  <div className={'text-center py-3'}>
                    <p className={'lead font-italic text-muted'}>
                      {translate['no_promotion_message']}
                    </p>
                  </div>
                );
              return (
                <Fragment>
                  <PromotionList history={history} promotions={customPages} />
                  {maxPage > 1 && (
                    <div className={'d-flex justify-content-center'}>
                      <StyledPagination
                        currentPage={skip / limit + 1}
                        maxPage={maxPage}
                        onClick={this.onPaginationClick}
                      />
                    </div>
                  )}
                </Fragment>
              );
            }}
          </Query>
        </Container>
      </Fragment>
    );
  }
}
