import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import Query from 'react-apollo/Query';
import gql from 'graphql-tag';
import translate from '../../../../shared/translate';
import Loading from '../../../../components/loading';
import EventItem from '../_eventItem';
import SuccessItem from './_successItem';
import BookingForm from './_bookingForm';
import { Helmet } from 'react-helmet';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Event {
        id
        name
        since
        active
        duration
        valid
        status
        createdAt
        orderItem {
          id
          order {
            id
            billingAddress {
              person
              tel
            }
          }
        }
        slots {
          id
          venue {
            id
            name
            images
            address {
              id
              person
              tel
              email
              lines
              country
            }
          }
          startedAt
          startedThru
        }
      }
    }
  }
`;

export default ({
  match: {
    params: { id }
  },
  history
}) => (
  <Fragment>
    <Helmet>
      <title>
        {translate['menu_events']} - {translate['meta.main.title']}
      </title>
    </Helmet>
    <Query query={GET_QUERY} variables={{ id }} fetchPolicy={'network-only'}>
      {({ loading, data }) => {
        if (loading) return <Loading />;
        const { node } = data || {};
        if (!node) {
          history.goBack();
          return null;
        }
        const { status } = node;

        return (
          <Row>
            <div className={'col-lg-6 offset-lg-3'}>
              <EventItem {...node} showButton={false} />
              {
                {
                  PENDING: (
                    <BookingForm
                      event={node}
                      onSuccess={() => {
                        history.replace('/profile/events');
                      }}
                    />
                  ),
                  CONFIRMED: <SuccessItem event={node} />,
                  COMPLETED: <SuccessItem event={node} />
                }[status]
              }
            </div>
          </Row>
        );
      }}
    </Query>
  </Fragment>
);
