import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import translate from '../../shared/translate';
import Loading from '../../components/loading';
import SingleSelector from '../../components/singleSelector';
import { client } from '../../shared/apollo';
import { toast } from 'react-toastify';
import { errorParser } from '../../shared/errorParser';

const GET_COUNTRY = gql`
  query {
    __type(name: "Country") {
      name
      enumValues {
        name
      }
    }
  }
`;

export default class CountrySelector extends Component {
  state = {
    loading: true,
    selected: null,
    countries: []
  };
  async componentDidMount() {
    let { value } = this.props;

    try {
      let {
        data: {
          __type: { enumValues }
        }
      } = await client.query({
        query: GET_COUNTRY,
        fetchPolicy: 'cache-first'
      });
      const countries = enumValues
        .filter(({ name }) => {
          const whiteList = ['HKG', 'CHN'];
          return whiteList.indexOf(name) >= 0;
        })
        .map(({ name }) => ({
          label: translate[name],
          value: name
        }));
      let selected = value
        ? countries.find(country => country.value === value)
        : null;

      this.setState({
        countries,
        selected
      });
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      this.setState({ loading: false });
    }
  }

  onChange = selected => {
    let { onChange } = this.props;
    this.setState({ selected });

    onChange && onChange(selected.value);
  };
  render() {
    let { disabled, name } = this.props;
    let { selected, loading, countries } = this.state;

    if (loading) return <Loading />;
    return (
      <SingleSelector
        name={name}
        value={selected}
        onChange={this.onChange}
        isDisabled={disabled}
        placeholderText={'請選擇地區'}
        options={countries}
      />
    );
  }
}

CountrySelector.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};
