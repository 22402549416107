import React from 'react';
import moment from 'moment';
import { auto2sc } from '../../shared/translate/tc2sc';
import translate from '../../shared/translate';

export default ({ history, promotions }) => (
  <div>
    <h1>{translate['menu_promotion']}</h1>
    <hr />
    {promotions.map((promotion, i) => (
      <PromotionListItem
        {...promotion}
        key={i}
        onClick={() => {
          const { href } = promotion;
          history.push(`/promotions/${href}`);
        }}
      />
    ))}
  </div>
);

const PromotionListItem = ({ href, name, createdAt, onClick }) => (
  <a
    className={'row mb-md-3 mb-5'}
    href={`/promotions/${href}`}
    onClick={e => {
      e.preventDefault();
      onClick && onClick();
    }}
  >
    <div className={'col-sm'}>
      <h6>{auto2sc(name)}</h6>
    </div>
    <div className={'col-sm-auto text-right'}>
      <p className={'mb-1'}>
        {moment(createdAt).format(translate['moment_format_date'])}
      </p>
    </div>
  </a>
);
