import React, { Fragment, PureComponent } from 'react';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import StyledNav from '../../components/styledNav';
import CountrySelector from './_countrySelector';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { client } from '../../shared/apollo';
import Loading from '../../components/loading';
import translate from '../../shared/translate';
import gql from 'graphql-tag';
import { errorParser } from '../../shared/errorParser';
import { Helmet } from 'react-helmet';

const REGISTER = gql`
  mutation($user: UserInput!, $credential: UserCredentialInput!) {
    customerRegister(user: $user, credential: $credential) {
      id
    }
  }
`;

const SET_ADDRESS = gql`
  mutation($id: ID, $input: AddressInput!) {
    addressSet(id: $id, input: $input) {
      id
    }
  }
`;

export default class extends PureComponent {
  register = async ({ attributes, identity, secret }) => {
    const {
      data: { customerRegister: { id } = {} } = {}
    } = await client.mutate({
      mutation: REGISTER,
      variables: {
        user: { attributes },
        credential: {
          platform: 'PASSWORD',
          identity,
          secret
        }
      }
    });

    return id;
  };
  addAddress = async ({ userId, person, tel, email, lines, country }) => {
    const { data: { addressSet: { id } = {} } = {} } = await client.mutate({
      mutation: SET_ADDRESS,
      variables: {
        id: undefined,
        input: {
          userId,
          person,
          tel,
          email,
          lines,
          country,
          attributes: []
        }
      }
    });
    return id;
  };
  submit = async (values, { setSubmitting }) => {
    try {
      let { password, confirm_password, email } = values;
      if (confirm_password !== password)
        throw new Error('Error: confirmation password not correct');

      const keysInAttribute = ['name'];
      let attributes = Object.keys(values)
        .filter(key => keysInAttribute.includes(key))
        .map(key => ({ key, value: values[key] }));

      const userId = await this.register({
        attributes,
        identity: email,
        secret: password
      });

      await this.addAddress({
        userId,
        person: values.name,
        tel: values.tel,
        email: values.email,
        lines: [values.address_1, values.address_2, values.address_3].filter(
          _ => _
        ),
        country: values.country
      });

      toast.success(translate['register_success']);
      this.props.history.push('/login');
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
    }
  };
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>
            {translate['new_register']} - {translate['meta.main.title']}
          </title>
        </Helmet>
        <Container className={'py-5'}>
          <StyledNav
            items={[
              {
                title: translate['login'],
                href: '/login'
              },
              {
                title: translate['new_register'],
                href: '/register',
                active: true
              }
            ]}
          />
          <Row>
            <div className={'col-md-6 offset-md-3'}>
              <Formik onSubmit={this.submit}>
                {({ values, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label className={'required'}>
                        {translate['name']} *
                      </Label>
                      <Input
                        name={'name'}
                        required={true}
                        value={values.name}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>{translate['contact_number']} *</Label>
                      <Input
                        name={'tel'}
                        required={true}
                        value={values.tel}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>{translate['email_address']} *</Label>
                      <Input
                        name={'email'}
                        required={true}
                        value={values.email}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>{translate['password']} *</Label>
                      <Input
                        name={'password'}
                        required={true}
                        type={'password'}
                        value={values.password}
                        minLength={8}
                        pattern={'(?=.*\\d)(?=.*[a-z]).{8,}'}
                        title={translate['password_pattern']}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>{translate['confirm_password']} *</Label>
                      <Input
                        name={'confirm_password'}
                        required={true}
                        type={'password'}
                        value={values.confirm_password}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>{translate['address']}</Label>
                      <Input
                        name={'address_1'}
                        value={values.address_1}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Input
                        name={'address_2'}
                        value={values.address_2}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Input
                        name={'address_3'}
                        value={values.address_3}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>{translate['country_region']}</Label>
                      <CountrySelector
                        name={'country'}
                        value={values.country}
                        onChange={value => (values.country = value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <div className='custom-control custom-checkbox'>
                        <Input
                          required={true}
                          name={'agreed'}
                          value={values.agreed}
                          onChange={handleChange}
                          type={'checkbox'}
                          className={'custom-control-input'}
                          id={'cb-country'}
                        />
                        <Label
                          className={'custom-control-label'}
                          for={'cb-country'}
                        >
                          {translate['register_agreement']
                            .split(/{(.*?)}/g)
                            .filter(_ => _)
                            .map((str, i) => {
                              switch (str) {
                                case 'privacy':
                                  return (
                                    <a
                                      key={i}
                                      href={'/pages/privacy'}
                                      target={'_blank'}
                                    >
                                      {translate['privacy']}
                                    </a>
                                  );
                                case 'tnc':
                                  return (
                                    <a
                                      key={i}
                                      href={'/pages/tnc'}
                                      target={'_blank'}
                                    >
                                      {translate['tnc']}
                                    </a>
                                  );
                                default:
                                  return <span key={i}>{str}</span>;
                              }
                            })}
                        </Label>
                      </div>
                    </FormGroup>

                    {isSubmitting ? (
                      <Loading />
                    ) : (
                      <FormGroup className={'text-center'}>
                        <Button
                          type={'submit'}
                          color={'primary'}
                          className={'btn-lg'}
                        >
                          {translate['submit']}
                        </Button>
                      </FormGroup>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
