import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { Icon } from '../../components/iconRender';
import Enum_paymentMethods from './_enum_paymentMethods';
import QRCodePaymentWidget from './_qrCodePaymentWidget';
import StripePaymentWidget from './_stripePaymentWidget';
import StripeConnectCustomPaymentWidget from './_stripeConnectCustomPaymentWidget';
import WechatBridgePaymentWidget from './_wechatBridgePaymentWidget';
import translate from '../../shared/translate';
import { toast } from 'react-toastify';
import Fingerprint2 from 'fingerprintjs2';
import Loading from '../../components/loading';
import { errorParser } from '../../shared/errorParser';
import checkoutSet from './checkouFun/checkoutSet';
import checkoutPaymentSet from './checkouFun/checkoutPaymentSet';
import checkoutGet from './checkouFun/checkoutGet';
import checkoutProcess from './checkouFun/checkouProcess';
import checkoutServiceSet from './checkouFun/checkoutServiceSet';
import _ from 'lodash';
import checkoutCouponSet from './checkouFun/checkoutCouponSet';

export default class PaymentCard extends Component {
  state = {
    loading: false,
    deviceId: undefined,
    providerType: undefined,
    checkoutId: undefined,
    checkoutToken: undefined
  };
  componentDidMount() {
    Fingerprint2.getV18({}, result => this.setState({ deviceId: result }));
  }
  onReturnFromPayment = async () => {
    this.setState({ providerType: undefined });
  };
  onStripeSuccess = async token => {
    const { id } = token;
    const { onSuccess } = this.props;
    const { checkoutId } = this.state;

    try {
      const result = await checkoutProcess({
        checkoutId,
        input: { token: id }
      });
      onSuccess && (await onSuccess(result));
      console.log(result);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      this.setState({ loading: false });
    }
  };
  checkoutFlow = async ({
    provider,
    userId,
    shopId,
    deviceId,
    attributes,
    serviceId,
    coupon
  }) => {
    // create empty checkout
    // add address
    const { id: checkoutId } = await checkoutSet({
      input: { shopId, attributes, userId, deviceId }
    });
    // add item to checkout
    await checkoutServiceSet({
      checkoutId,
      input: {
        serviceId: serviceId
      }
    });
    // Set Coupon
    if (coupon) await checkoutCouponSet({ checkoutId, couponId: coupon.id });

    // switch payment provider
    await checkoutPaymentSet({
      checkoutId,
      input: {
        provider
      }
    });
    // return checkout object
    return checkoutGet(checkoutId);
  };

  onPaymentSelect = async provider => {
    const {
      userId,
      coupon,
      service: { id: serviceId } = {},
      shopId,
      addressData,
      onSuccess,
      onSelectPaymentMethod
    } = this.props;

    const { deviceId } = this.state;

    this.setState({ loading: true });

    try {
      let json = JSON.stringify(addressData);
      const { id, payment: { token } = {} } = await this.checkoutFlow({
        provider,
        userId,
        shopId,
        deviceId,
        attributes: [
          { key: 'buyer', value: json },
          { key: 'receiver', value: json }
        ],
        serviceId,
        coupon
      });

      this.setState({
        providerType: provider.replace(/^.*_(.*?)$/, '$1') || provider,
        checkoutId: id,
        checkoutToken: token
      });

      onSelectPaymentMethod && onSelectPaymentMethod(provider);

      if (provider === 'MANUAL') {
        const result = await checkoutProcess({
          checkoutId: id,
          input: { token }
        });

        onSuccess && (await onSuccess(result));
      }
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      this.setState({ loading: false });
    }
  };
  render() {
    const { providerType, loading } = this.state;
    if (loading)
      return (
        <Container className={'text-center'}>
          <Loading />
        </Container>
      );
    return (
      <Container className={'text-center p-0'}>
        {!providerType && this.renderPaymentList()}
        {providerType && this.renderPaymentProvider()}
      </Container>
    );
  }
  renderPaymentList = () => {
    const { credentials } = this.props;
    const isWechatBrowser = /micromessenger/i.test(navigator.userAgent);
    const activeCredentials = credentials.filter(
      ({ active, platform }) =>
        active !== false && !!Enum_paymentMethods[platform]
    );
    if (activeCredentials.length === 0)
      return (
        <Fragment>
          <h4>{translate['payment_temporarily_suspended']}</h4>
        </Fragment>
      );

    const references = ['WECHAT', 'ALIPAY', 'STRIPE'];

    const sortedCredentials = _.sortBy(activeCredentials, ({ platform }) =>
      _.findIndex(references, str => platform.match(str))
    );

    return (
      <Fragment>
        <h4>{translate['select_payment_method']}</h4>
        <hr />
        {sortedCredentials.map(({ platform }, i) => {
          let { bg, name, icon, providers = {} } =
            Enum_paymentMethods[platform] || {};
          let provider;

          if (isMobile) {
            if (isWechatBrowser && providers.wechat)
              provider = providers.wechat;
            else provider = providers.mobile;
          } else {
            provider = providers.desktop;
          }

          if (!provider) return null;
          return (
            <div className={'mb-2'} key={i}>
              <Button
                className={'col-md-auto'}
                style={{ backgroundColor: bg, minWidth: 180 }}
                onClick={() => this.onPaymentSelect(provider)}
              >
                <Icon icon={icon} />
                <span className={'ml-2'}>{name}</span>
              </Button>
            </div>
          );
        })}
      </Fragment>
    );
  };
  renderPaymentProvider = () => {
    const { onSuccess, addressData } = this.props;
    const { providerType, checkoutToken, checkoutId } = this.state;
    switch (providerType) {
      case 'BRIDGE':
        return (
          <WechatBridgePaymentWidget
            token={checkoutToken}
            onSuccess={onSuccess}
            checkoutId={checkoutId}
            onReturn={this.onReturnFromPayment}
          />
        );
      case 'SCAN':
        return (
          <QRCodePaymentWidget
            token={checkoutToken}
            onSuccess={onSuccess}
            onReturn={this.onReturnFromPayment}
            checkoutId={checkoutId}
          />
        );
      case 'CARD':
        return (
          <StripePaymentWidget
            addressData={addressData}
            onSuccess={this.onStripeSuccess}
          />
        );
      case 'CUSTOM':
        return (
          <StripeConnectCustomPaymentWidget
            addressData={addressData}
            onSuccess={this.onStripeSuccess}
          />
        );
      default:
        return null;
    }
  };
}

PaymentCard.propTypes = {
  coupon: PropTypes.string,
  service: PropTypes.object,
  shopId: PropTypes.string,
  credentials: PropTypes.array,
  addressData: PropTypes.object,
  onSelectPaymentMethod: PropTypes.func,
  onSuccess: PropTypes.func
};
