import React from 'react';
import { Row } from 'reactstrap';
import { Icon } from './iconRender';
import Select from 'react-select';

export default ({
  options = [],
  isDisabled = false,
  placeholder,
  placeholderText,
  placeholderIcon,
  onChange,
  defaultValue,
  value,
  required = true,
  name
}) => (
  <Select
    name={name}
    defaultValue={defaultValue}
    value={value}
    onChange={onChange}
    isDisabled={isDisabled}
    placeholder={
      placeholder ||
      (placeholderText && (
        <Row className={'pl-3 align-items-center'}>
          {placeholderIcon && <Icon icon={placeholderIcon} size={'1x'} />}
          <span className={'ml-2'}>{placeholderText}</span>
        </Row>
      ))
    }
    options={options}
  />
);
