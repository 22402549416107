import React, { useState } from 'react';
import { Icon } from './iconRender';
import { Row } from 'reactstrap';
import WeiXin from '../assets/weixin.png';

export default ({ facebook, weibo, weixin, size = '1x', header }) => {
  const [hover, setHover] = useState(false);
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <Row>
        {facebook && (
          <a
            href={facebook}
            target='_blank'
            rel='noopener noreferrer'
            className={'mr-1 ml-1'}
          >
            <Icon icon={'faFacebookSquare'} color={'#3b539c'} size={size} />
          </a>
        )}

        {weibo && (
          <a
            href={weibo}
            target='_blank'
            rel='noopener noreferrer'
            className={'mr-1 ml-1'}
          >
            <Icon icon={'faWeibo'} color={'#e60002'} size={size} />
          </a>
        )}

        {weixin && (
          <a
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
            href={weixin}
            target='_blank'
            rel='noopener noreferrer'
            className={'mr-1 ml-1'}
          >
            <Icon icon={'faWeixin'} color={'#16c446'} size={size} />
          </a>
        )}
      </Row>
      {hover && !header && (
        <img
          alt={'WeiXin'}
          className={'d-none d-lg-block'}
          style={{ position: 'absolute', top: '-1500%' }}
          src={WeiXin}
        />
      )}
      {hover && header && (
        <img
          alt={'WeiXin'}
          className={'d-none d-lg-block'}
          style={{ position: 'absolute', zIndex: '1', left: '-400%' }}
          src={WeiXin}
        />
      )}
    </div>
  );
};
