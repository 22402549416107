import React, { Fragment, useState } from 'react';
import translate from '../../shared/translate';
import { Helmet } from 'react-helmet';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { Formik } from 'formik';
import Loading from '../../components/loading';
import { client } from '../../shared/apollo';
import { toast } from 'react-toastify';
import { errorParser } from '../../shared/errorParser';
import { REQUEST_RESET_PASSWORD } from './query';

export default ({ history }) => {
  const [success, setSuccess] = useState(false);
  const submit = async ({ identity }, { setSubmitting }) => {
    try {
      await client.mutate({
        mutation: REQUEST_RESET_PASSWORD,
        variables: {
          identity
        }
      });
      setSuccess(true);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>
          {translate['forget_password']} - {translate['meta.main.title']}
        </title>
      </Helmet>
      <Container className={'py-5'}>
        <h2 className={'text-center'}>{translate['forget_password']}</h2>
        <Row>
          <div className={'col-md-6 offset-md-3'}>
            {!success && (
              <Formik onSubmit={submit}>
                {({ handleSubmit, handleChange, values, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label htmlFor={'email'}>
                        {translate['email_address']}
                      </Label>
                      <Input
                        required={true}
                        name={'identity'}
                        value={values.identity}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    {isSubmitting ? (
                      <Loading />
                    ) : (
                      <FormGroup className={'text-center'}>
                        <Button
                          type={'submit'}
                          color={'primary'}
                          className={'px-5'}
                        >
                          {translate['submit']}
                        </Button>
                      </FormGroup>
                    )}
                  </Form>
                )}
              </Formik>
            )}
            {!!success && (
              <div className={'text-center'}>
                <p className={'lead'}>
                  {translate['forget_password_email_message']}
                </p>
                <Button
                  outline={true}
                  className={'mt-3'}
                  onClick={() => {
                    history.replace('/');
                  }}
                >
                  <span>{translate['back_to_home']}</span>
                </Button>
              </div>
            )}
          </div>
        </Row>
      </Container>
    </Fragment>
  );
};
