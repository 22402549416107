import React, { Component } from 'react';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import Loading from '../../components/loading';
import { client } from '../../shared/apollo';
import { errorParser } from '../../shared/errorParser';
import translate from '../../shared/translate';

const WECHAT_AUTH = gql`
  mutation($input: String!) {
    sessionCreateByWechat(input: $input)
  }
`;

export default class extends Component {
  async componentDidMount() {
    const { referer, history } = this.props;
    try {
      const code = this.getUrlParameter('code');
      if (!code) return;
      const {
        data: { sessionCreateByWechat: token }
      } = await client.mutate({
        mutation: WECHAT_AUTH,
        variables: {
          input: code
        }
      });
       localStorage.setItem('token', token);
      await client.resetStore();
      toast.success(translate['login_success']);

      history.replace(referer || '/profile');
    } catch (e) {
      toast.error(errorParser(e));
      history.replace('/');
    }
  }

  getUrlParameter(name) {
    const { location: { search } = {} } = this.props;
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  render() {
    return (
      <div
        className={`d-flex justify-content-center align-items-center flex-column py-5`}
      >
        <Loading />
        <h4>微信登入中</h4>
      </div>
    );
  }
}
