import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import CountrySelector from '../../register/_countrySelector';
import { Icon } from '../../../components/iconRender';
import { Formik } from 'formik';
import Loading from '../../../components/loading';
import translate from '../../../shared/translate';

export default ({ onSubmit, onCancel, initialValues }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={initialValues}
    enableReinitialize={true}
  >
    {({ values, handleSubmit, handleChange, isSubmitting }) => (
      <Form onSubmit={handleSubmit}>
        <FormGroup className={'row'}>
          <Label className={'col-sm-2 col-form-label'}>
            {translate['name']}
          </Label>
          <div className={'col-sm'}>
            <Input
              required={true}
              onChange={handleChange}
              name={'person'}
              value={values.person}
            />
          </div>
        </FormGroup>

        <FormGroup className={'row'}>
          <Label className={'col-sm-2 col-form-label'}>
            {translate['contact_number']}
          </Label>
          <div className={'col-sm'}>
            <Input
              required={true}
              onChange={handleChange}
              name={'tel'}
              value={values.tel}
            />
          </div>
        </FormGroup>

        <FormGroup className={'row'}>
          <Label className={'col-sm-2 col-form-label'}>
            {translate['email_address']}
          </Label>
          <div className={'col-sm'}>
            <Input
              onChange={handleChange}
              name={'email'}
              value={values.email}
            />
          </div>
        </FormGroup>

        <FormGroup className={'row'}>
          <Label className={'col-sm-2 col-form-label'}>
            {translate['address']}
          </Label>
          <div className={'col-sm'}>
            <Input
              onChange={handleChange}
              name={'line_1'}
              value={values.line_1}
            />
          </div>
        </FormGroup>

        <FormGroup className={'row'}>
          <div className={'offset-sm-2 col-sm'}>
            <Input
              onChange={handleChange}
              name={'line_2'}
              value={values.line_2}
            />
          </div>
        </FormGroup>

        <FormGroup className={'row'}>
          <div className={'offset-sm-2 col-sm'}>
            <Input
              onChange={handleChange}
              name={'line_3'}
              value={values.line_3}
            />
          </div>
        </FormGroup>

        <FormGroup className={'row'}>
          <Label className={'col-sm-2 col-form-label'}>
            {translate['country_region']}
          </Label>
          <div className={'col-sm'}>
            <CountrySelector
              name={'country'}
              value={values.country}
              onChange={value => (values.country = value)}
            />
          </div>
        </FormGroup>

        {isSubmitting ? (
          <Loading />
        ) : (
          <FormGroup className={'text-center'}>
            <Button
              type={'submit'}
              color={'dark'}
              outline={true}
              className={'px-5'}
            >
              {translate['save']}
            </Button>
            {onCancel && (
              <Button
                color={'danger'}
                outline={true}
                onClick={onCancel}
                className={'ml-1'}
              >
                <Icon icon={'faTrashAlt'} />
              </Button>
            )}
          </FormGroup>
        )}
      </Form>
    )}
  </Formik>
);
