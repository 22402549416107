import React, { Component } from 'react';
import { Formik } from 'formik';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Loading from '../../../components/loading';
import gql from 'graphql-tag';
import { client } from '../../../shared/apollo';
import translate from '../../../shared/translate';
import {errorParser} from "../../../shared/errorParser";
import {Helmet} from "react-helmet";

const ME = gql`
  query {
    me {
      id
      credentials {
        id
        identity
      }
    }
  }
`;

const LOGIN = gql`
  mutation($identity: String!, $secret: String!) {
    sessionCreate(identity: $identity, secret: $secret)
  }
`;

const CREDENTIAL_SET = gql`
  mutation($userId: ID!, $credential: UserCredentialInput!) {
    userCredentialSet(userId: $userId, credential: $credential) {
      id
    }
  }
`;

export default class extends Component {
  login = async ({ identity, secret }) => {
    const { data: { sessionCreate: token } = {} } = await client.mutate({
      mutation: LOGIN,
      variables: {
        identity,
        secret
      }
    });
    return token;
  };
  modifyPassword = async ({ userId, identity, secret }) => {
    const {
      data: {
        userCredentialSet: { id }
      }
    } = await client.mutate({
      mutation: CREDENTIAL_SET,
      variables: {
        userId,
        credential: {
          identity,
          secret
        }
      }
    });
    return id;
  };
  submit = async (values, { setSubmitting, resetForm }) => {
    let { id, identity, old_password, password, confirm_password } = values;
    try {
      if (password !== confirm_password)
        throw new Error('Error: confirmation password not correct');

      await this.login({ identity, secret: old_password });
      await this.modifyPassword({ userId: id, identity, secret: password });
      resetForm({
        id,
        identity,
        old_password: '',
        password: '',
        confirm_password: ''
      });
      toast.success(translate['update_success']);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
    }
  };
  render() {
    return (
      <div className={'col-md-6 offset-md-3'}>
        <Helmet>
          <title>
            {translate['menu_change_password']} - {translate['meta.main.title']}
          </title>
        </Helmet>
        <Query query={ME}>
          {({ loading, data }) => {
            if (loading) return <Loading />;
            let {
              me: {
                id,
                credentials: [{ identity }] = [{}]
              } = {}
            } = data || {};
            return (
              <Formik onSubmit={this.submit} initialValues={{ id, identity }}>
                {({ values, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className={'row'}>
                      <Label className={'col-sm-3 col-form-label'}>{translate['password']}</Label>
                      <div className={'col-sm'}>
                        <Input
                          required={true}
                          type={'password'}
                          value={values.old_password}
                          name={'old_password'}
                          onChange={handleChange}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className={'row'}>
                      <Label className={'col-sm-3 col-form-label'}>
                        {translate['new_password']}
                      </Label>
                      <div className={'col-sm'}>
                        <Input
                          required={true}
                          minLength={8}
                          type={'password'}
                          value={values.password}
                          name={'password'}
                          placeholder={''}
                          pattern={'(?=.*\\d)(?=.*[a-z]).{8,}'}
                          title={translate['password_pattern']}
                          onChange={handleChange}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className={'row'}>
                      <Label className={'col-sm-3 col-form-label'}>
                        {translate['confirm_password']}
                      </Label>
                      <div className={'col-sm'}>
                        <Input
                          required={true}
                          minLength={8}
                          type={'password'}
                          value={values.confirm_password}
                          name={'confirm_password'}
                          onChange={handleChange}
                        />
                      </div>
                    </FormGroup>

                    {isSubmitting ? (
                      <Loading />
                    ) : (
                      <FormGroup className={'text-center'}>
                        <Button
                          type={'submit'}
                          outline={true}
                          className={'px-5'}
                        >
                          {translate['save']}
                        </Button>
                      </FormGroup>
                    )}
                  </Form>
                )}
              </Formik>
            );
          }}
        </Query>
      </div>
    );
  }
}
