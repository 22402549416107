import React, { Component, Fragment } from 'react';
import { Button, Row } from 'reactstrap';
import EventItem from './_eventItem';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Loading from '../../../components/loading';
import translate from '../../../shared/translate';
import StyledPagination from '../../../components/styledPagination';
import { Helmet } from 'react-helmet';

const GET_QUERY = gql`
  query($skip: Int, $limit: Int) {
    me {
      id
      eventsCount
      events(offset: { skip: $skip, limit: $limit }) {
        id
        name
        createdAt
        active
        valid
        since
        duration
        status
        orderItem {
          id
          order {
            id
            billingAddress {
              person
              tel
            }
          }
        }
        slots {
          id
          startedAt
        }
      }
    }
  }
`;

export default class Events extends Component {
  state = {
    skip: 0,
    limit: 5
  };

  onPaginationClick = async page => {
    let { limit } = this.state;
    this.setState({ skip: limit * (page - 1) });
  };

  render() {
    const { history } = this.props;
    const { skip, limit } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>
            {translate['menu_events']} - {translate['meta.main.title']}
          </title>
        </Helmet>
        <Query
          query={GET_QUERY}
          variables={{ skip, limit }}
          fetchPolicy={'network-only'}
        >
          {({ loading, data }) => {
            if (loading) return <Loading />;
            const { me } = data || {};
            const { events = [], eventsCount = 0 } = me || {};
            let maxPage = Math.ceil(eventsCount / limit);

            if (eventsCount === 0)
              return (
                <div className={'text-center py-3'}>
                  <p className={'lead font-italic text-muted'}>
                    {translate['no_event_message']}
                  </p>
                  <Button
                    color={'primary'}
                    href={'/services'}
                    onClick={e => {
                      e.preventDefault();
                      history.push('/services');
                    }}
                  >
                    {translate['menu_service']}
                  </Button>
                </div>
              );
            return (
              <Fragment>
                <Row>
                  <div className={'col-lg-6 offset-lg-3'}>
                    {events.map((event, i) => (
                      <EventItem key={i} {...event} history={history} />
                    ))}
                  </div>
                </Row>
                {maxPage > 1 && (
                  <div className={'d-flex justify-content-center'}>
                    <StyledPagination
                      currentPage={skip / limit + 1}
                      maxPage={maxPage}
                      onClick={this.onPaginationClick}
                    />
                  </div>
                )}
              </Fragment>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}
