import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Collapse
} from 'reactstrap';
import TopContentBar from './topContentBar';
import { Icon } from './iconRender';

export default class HeaderNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    let { collapse } = this.state;
    this.setState({ collapse: !collapse });
  }

  render() {
    let { topBarProps, pages = [], imageLink = [], onMenuClick } = this.props;
    let { collapse } = this.state;
    console.log(topBarProps);
    return (
      <div>
        <Navbar expand={'md'} className={'d-none d-sm-block'}>
          <TopContentBar {...topBarProps} />
        </Navbar>
        <Navbar expand={'md'} className='p-0 px-sm-3 py-sm-2'>
          <Container>
            <Col>
              <Row>
                <a
                  href='/'
                  onClick={e => {
                    e.preventDefault();
                    onMenuClick && onMenuClick('/');
                  }}
                  className='col-md-3 col navbar-brand pl-0'
                >
                  <img
                    className={'img-fluid'}
                    alt='HC Healthcare Limited'
                    src={require('../assets/hchealth.hk.jpg')}
                  />
                </a>
                <button
                  className={'navbar-toggler border-0 collapsed'}
                  onClick={this.toggle}
                >
                  <Icon icon={'faBars'} />
                </button>
                <Navbar
                  expand={'md'}
                  className={'navbar-collapse collapse pr-0'}
                >
                  <Nav className={'mr-auto px-sm-3 px-md-0'}>
                    {pages.map(({ title, href }, i) => (
                      <NavItem key={i}>
                        <NavLink
                          href={href}
                          onClick={e => {
                            e.preventDefault();
                            onMenuClick && onMenuClick(href);
                          }}
                        >
                          {title}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </Navbar>
                {imageLink.length > 0 &&
                  imageLink.map(({ href, target = '_blank', alt, src }, i) => (
                    <a
                      key={i}
                      href={href}
                      target={target}
                      className={`d-none d-lg-block px-1 px-xl-2`}
                    >
                      <ResponsiveImage
                        alt={alt}
                        className={'img-fluid'}
                        src={src}
                      />
                    </a>
                  ))}
              </Row>
              <Row>
                <Collapse isOpen={!collapse} className={'d-md-none'}>
                  <Nav vertical className={'mr-auto px-sm-3 px-md-0'}>
                    {pages.map(({ title, href }, i) => (
                      <NavItem key={i}>
                        <NavLink
                          href={href}
                          onClick={e => {
                            e.preventDefault();
                            onMenuClick && onMenuClick(href);
                          }}
                        >
                          {title}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </Collapse>
              </Row>
            </Col>
          </Container>
        </Navbar>
      </div>
    );
  }
}

HeaderNav.propTypes = {
  topBarProps: PropTypes.any,
  pages: PropTypes.array,
  imageLink: PropTypes.array,
  onMenuClick: PropTypes.func
};

export const ResponsiveImage = ({ alt, className, src }) => (
  <Fragment>
    <img
      alt={alt}
      className={`d-none d-xl-inline-block ${className}`}
      src={src}
      width={120}
    />
    <img
      alt={alt}
      className={`d-inline-block d-xl-none ${className}`}
      src={src}
      width={80}
    />
  </Fragment>
);
