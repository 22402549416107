import React from 'react';
import { Row, Col } from 'reactstrap';

export default ({ text, type = 'light', underlineColor = '#3fa5f1' }) => (
  <h4 className={`title text-${type} text-center text-sm-left`}>
    <span>{text}</span>
    <Stripe backgroundColor={underlineColor} />
  </h4>
);

export const Stripe = ({ backgroundColor }) => (
  <Row className={'m-0'}>
    <Col className={'d-block d-sm-none'} />
    <div style={{ ...styles.stripe, backgroundColor }} />
    <Col />
  </Row>
);

const styles = {
  stripe: {
    width: '3em',
    height: 3
  }
};
