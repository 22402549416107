import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import './style.scss';
import { Container, Row } from 'reactstrap';
import StyledTitle from '../styledTitle';

export default ({ images }) => {
  function compare(property) {
    return function(obj1, obj2) {
      let value1 = obj1[property];
      let value2 = obj2[property];
      return value1 - value2;
    };
  }
  let sortObj = images.sort(compare('sortIndex'));

  return (
    <Slider
      className='slick-slider-wrapper embed-responsive-item'
      autoplaySpeed={15000}
      autoplay={true}
      dots={true}
    >
      {sortObj.map(({ image, content, title, href = '/', sortIndex }, i) => {
        return (
          <div key={i} className={'banner-wrapper'}>
            {href && (
              <Link className='text-white' to={href}>
                <img
                  className='banner object-fit-cover'
                  src={image}
                  alt=''
                  style={{ objectFit: 'cover' }}
                />
                <div className='position-absolute w-100' style={{ top: 0 }}>
                  <div className={'text-center text-sm-left w-100 p-5'}>
                    <Container>
                      <Row>
                        <div className={'col-xl-6'}>
                          {title && <StyledTitle text={title} />}
                          {content && (
                            <p
                              className={'d-block text-light content-wrapper'}
                              style={{ whiteSpace: 'pre-line' }}
                              dangerouslySetInnerHTML={{ __html: content }}
                            />
                          )}
                        </div>
                      </Row>
                    </Container>
                  </div>
                  <div
                    className='d-flex justify-content-center align-items-center'
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div>
              </Link>
            )}
            {!href && (
              <div>
                <img
                  className='banner object-fit-cover'
                  src={image}
                  alt=''
                  style={{ objectFit: 'cover' }}
                />
                <div className='position-absolute w-100' style={{ top: 0 }}>
                  <div className={'text-center text-sm-left w-100 p-5'}>
                    <Container>
                      <Row>
                        <div className={'col-xl-6'}>
                          {title && <StyledTitle text={title} />}
                          {content && (
                            <p
                              className={'d-block text-light content-wrapper'}
                              style={{ whiteSpace: 'pre-line' }}
                              dangerouslySetInnerHTML={{ __html: content }}
                            />
                          )}
                        </div>
                      </Row>
                    </Container>
                  </div>
                  <div
                    className='d-flex justify-content-center align-items-center'
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </Slider>
  );
};
