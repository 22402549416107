import { client } from '../../../shared/apollo';
import gql from 'graphql-tag';
import {errorParser} from "../../../shared/errorParser";

const checkoutPaymentSet = async ({ checkoutId, input }) => {
  const { provider } = input;
  try {
    const {
      data: { checkoutPaymentSet }
    } = await client.mutate({
      mutation: gql`
        mutation($id: ID!, $input: CheckoutPaymentInput!) {
          checkoutPaymentSet(id: $id, input: $input) {
            id
            status
            payment {
              provider
              #              token
              #              charge
            }
          }
        }
      `,
      variables: {
        id: checkoutId,
        input: {
          provider
        }
      }
    });
    return checkoutPaymentSet;
  } catch (e) {
    throw new Error(errorParser(e.message));
  }
};

export default checkoutPaymentSet;
