import { WebSocketLink } from 'apollo-link-ws';
import WebSocket from 'isomorphic-ws';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import { onError } from 'apollo-link-error';
import { from } from 'apollo-link';

const { REACT_APP_API_URL, REACT_APP_WS_URL, REACT_APP_REFERRER } = process.env;

const httpLink = createUploadLink({
  uri: REACT_APP_API_URL
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'Node',
          possibleTypes: [
            {
              name: 'Query'
            },
            {
              name: 'ID'
            },
            {
              name: 'String'
            },
            {
              name: 'Node'
            },
            {
              name: 'Attribute'
            },
            {
              name: 'DateTime'
            },
            {
              name: 'Shop'
            },
            {
              name: 'Company'
            },
            {
              name: 'CompanyStatus'
            },
            {
              name: 'ShopFilterInput'
            },
            {
              name: 'FilterInput'
            },
            {
              name: 'AttributeInput'
            },
            {
              name: 'OffsetInput'
            },
            {
              name: 'Int'
            },
            {
              name: 'Warehouse'
            },
            {
              name: 'StockItem'
            },
            {
              name: 'StockMovement'
            },
            {
              name: 'StockMovementStatus'
            },
            {
              name: 'StockMovementDirection'
            },
            {
              name: 'StockMovementItem'
            },
            {
              name: 'Address'
            },
            {
              name: 'Country'
            },
            {
              name: 'Boolean'
            },
            {
              name: 'CredentialFilter'
            },
            {
              name: 'ShopCredentialPlatform'
            },
            {
              name: 'ShopCredentialType'
            },
            {
              name: 'ShopCredential'
            },
            {
              name: 'WechatMenu'
            },
            {
              name: 'User'
            },
            {
              name: 'UserGroup'
            },
            {
              name: 'UserCredential'
            },
            {
              name: 'UserCredentialPlatform'
            },
            {
              name: 'AgentService'
            },
            {
              name: 'AgentServiceFilter'
            },
            {
              name: 'ServiceApplicationStatus'
            },
            {
              name: 'ServiceApplication'
            },
            {
              name: 'ApplicationComment'
            },
            {
              name: 'Billing'
            },
            {
              name: 'EventFilterInput'
            },
            {
              name: 'EventStatus'
            },
            {
              name: 'Event'
            },
            {
              name: 'VenueSlot'
            },
            {
              name: 'Venue'
            },
            {
              name: 'SlotFilterInput'
            },
            {
              name: 'AvailableVenuesFilterInput'
            },
            {
              name: 'District'
            },
            {
              name: 'OrderFilterInput'
            },
            {
              name: 'OrderStatus'
            },
            {
              name: 'Order'
            },
            {
              name: 'OrderItem'
            },
            {
              name: 'ProductVariant'
            },
            {
              name: 'Float'
            },
            {
              name: 'Product'
            },
            {
              name: 'ProductType'
            },
            {
              name: 'ProductCategory'
            },
            {
              name: 'Collection'
            },
            {
              name: 'ProductFilterInput'
            },
            {
              name: 'Price'
            },
            {
              name: 'Comment'
            },
            {
              name: 'DeliveryOption'
            },
            {
              name: 'CourierType'
            },
            {
              name: 'DeliveryOptionWeight'
            },
            {
              name: 'Coupon'
            },
            {
              name: 'Image'
            },
            {
              name: 'URL'
            },
            {
              name: 'ImageContentType'
            },
            {
              name: 'DiscountType'
            },
            {
              name: 'Checkout'
            },
            {
              name: 'CheckoutStatus'
            },
            {
              name: 'CheckoutItem'
            },
            {
              name: 'CheckoutPayment'
            },
            {
              name: 'CheckoutPaymentProvider'
            },
            {
              name: 'Invoice'
            },
            {
              name: 'InvoiceStatus'
            },
            {
              name: 'InvoiceItem'
            },
            {
              name: 'DeliveryNote'
            },
            {
              name: 'DeliveryNoteStatus'
            },
            {
              name: 'DeliveryNoteItem'
            },
            {
              name: 'InvoiceFilterInput'
            },
            {
              name: 'Article'
            },
            {
              name: 'Banner'
            },
            {
              name: 'CollectionFilterInput'
            },
            {
              name: 'CustomPagesFilterInput'
            },
            {
              name: 'CustomPage'
            },
            {
              name: 'Component'
            },
            {
              name: 'DeliveryOptionFilterInput'
            },
            {
              name: 'ProductVariantFilterInput'
            },
            {
              name: 'CouponFilterInput'
            },
            {
              name: 'DiscountFilterInput'
            },
            {
              name: 'Discount'
            },
            {
              name: 'DiscountTarget'
            },
            {
              name: 'DiscountTargetItem'
            },
            {
              name: 'DiscountAction'
            },
            {
              name: 'DiscountActionItem'
            },
            {
              name: 'ReportType'
            },
            {
              name: 'DateGroup'
            },
            {
              name: 'Report'
            },
            {
              name: 'ReportData'
            },
            {
              name: 'ServiceFilterInput'
            },
            {
              name: 'Service'
            },
            {
              name: 'ServiceItem'
            },
            {
              name: 'TaxFilterInput'
            },
            {
              name: 'Tax'
            },
            {
              name: 'ShopUserFilterInput'
            },
            {
              name: 'VenueFilterInput'
            },
            {
              name: 'Plan'
            },
            {
              name: 'PlanInterval'
            },
            {
              name: 'Admin'
            },
            {
              name: 'RootUserFilterInput'
            },
            {
              name: 'Mutation'
            },
            {
              name: 'AddressInput'
            },
            {
              name: 'AgentServiceInput'
            },
            {
              name: 'ServiceApplicationInput'
            },
            {
              name: 'ApplicationCommentInput'
            },
            {
              name: 'ArticleInput'
            },
            {
              name: 'ImageInput'
            },
            {
              name: 'Upload'
            },
            {
              name: 'BannerInput'
            },
            {
              name: 'BillingInput'
            },
            {
              name: 'CollectionInput'
            },
            {
              name: 'CompanyInput'
            },
            {
              name: 'ShopInput'
            },
            {
              name: 'ShopCredentialInput'
            },
            {
              name: 'CustomPageInput'
            },
            {
              name: 'ComponentInput'
            },
            {
              name: 'DeliveryOptionInput'
            },
            {
              name: 'DeliveryOptionWeightInput'
            },
            {
              name: 'Success'
            },
            {
              name: 'PlanInput'
            },
            {
              name: 'ProductTypeInput'
            },
            {
              name: 'ProductInput'
            },
            {
              name: 'ProductVariantInput'
            },
            {
              name: 'ProductCategoryInput'
            },
            {
              name: 'CouponInput'
            },
            {
              name: 'DiscountInput'
            },
            {
              name: 'DiscountTargetInput'
            },
            {
              name: 'DiscountActionInput'
            },
            {
              name: 'PromotionRecordInput'
            },
            {
              name: 'promotionRecord'
            },
            {
              name: 'CheckoutInput'
            },
            {
              name: 'CheckoutItemInput'
            },
            {
              name: 'CheckoutPaymentInput'
            },
            {
              name: 'ExchangeRate'
            },
            {
              name: 'DeliveryNoteInput'
            },
            {
              name: 'DeliveryNoteItemInput'
            },
            {
              name: 'OrderInvoiceInput'
            },
            {
              name: 'OrderInvoiceItemInput'
            },
            {
              name: 'InvoicePaymentMethod'
            },
            {
              name: 'OrderInput'
            },
            {
              name: 'OrderItemInput'
            },
            {
              name: 'CommentInput'
            },
            {
              name: 'ServiceInput'
            },
            {
              name: 'ServiceItemInput'
            },
            {
              name: 'WarehouseInput'
            },
            {
              name: 'StockMovementInput'
            },
            {
              name: 'StockMovementItemInput'
            },
            {
              name: 'TaxInput'
            },
            {
              name: 'UserInput'
            },
            {
              name: 'UserGroupInput'
            },
            {
              name: 'UserCredentialInput'
            },
            {
              name: 'userRegisterShopInput'
            },
            {
              name: 'VendorInput'
            },
            {
              name: 'VenueInput'
            },
            {
              name: 'VenueSlotInput'
            },
            {
              name: 'Subscription'
            },
            {
              name: 'CheckoutMsg'
            },
            {
              name: '__Schema'
            },
            {
              name: '__Type'
            },
            {
              name: '__TypeKind'
            },
            {
              name: '__Field'
            },
            {
              name: '__InputValue'
            },
            {
              name: '__EnumValue'
            },
            {
              name: '__Directive'
            },
            {
              name: '__DirectiveLocation'
            },
            {
              name: 'WechatMenuInput'
            },
            {
              name: 'SortOrder'
            },
            {
              name: 'Connection'
            },
            {
              name: 'PageInfo'
            },
            {
              name: 'Edge'
            },
            {
              name: 'File'
            },
            {
              name: 'SorterInput'
            },
            {
              name: 'Duration'
            },
            {
              name: 'Currency'
            },
            {
              name: 'PriceInput'
            },
            {
              name: 'UserStatus'
            },
            {
              name: 'Session'
            },
            {
              name: 'CacheControlScope'
            }
          ]
        }
      ]
    }
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token');
  const _headers = {};
  if (!!token) _headers.authorization = `Bearer ${token}`;
  if (!!REACT_APP_REFERRER) _headers.referrer = REACT_APP_REFERRER;

  return {
    headers: {
      ...headers,
      ..._headers
    }
  };
});

const wsLink = new WebSocketLink({
  uri: REACT_APP_WS_URL,
  options: {
    reconnect: true
  },
  webSocketImpl: WebSocket
});

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  // let msg = '系統異常，請重試。';
  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.forEach(({ message, locations, path }, index) => {
      graphQLErrors[index].message = message.replace('GraphQL error:', '');
    });
    const message = graphQLErrors[0].message;
    if (
      ['Context creation failed', 'Session invalid'].reduce(
        (reducer, check) => {
          if (reducer) return reducer;
          return !!message.match(check);
        },
        false
      )
    ) {
      client.resetStore();
      localStorage.removeItem('token');
    }
    // msg = translate[message] || msg;
  }
  // if (networkError) toast.error(errorParser(networkError));
});

const link = from([
  errorLink,
  authLink.split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  )
]);

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({ fragmentMatcher })
});
