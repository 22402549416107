import React, { Component, Fragment } from 'react';
import { Container } from 'reactstrap';
import { Query } from 'react-apollo/index';
import Loading from '../../components/loading';
import translate from '../../shared/translate';
import ServiceCard from './_serviceCard';
import AddressAndCouponSelectCard from './AddressAndCouponSelectCard';
import PaymentCard from './_paymentCard';
import SuccessCard from './_successCard';
import { auto2sc } from '../../shared/translate/tc2sc';
import { Helmet } from 'react-helmet';
import { Input, Label } from 'reactstrap';
import { Formik } from 'formik';
import { GET_ME } from './query';

export default class extends Component {
  state = {
    coupon: undefined,
    selectedAddress: undefined,
    selectedProvider: undefined,
    result: undefined
  };
  componentDidMount() {
    let { history } = this.props;
    if (!localStorage.getItem('token'))
      history.replace('/login', { referer: history.location.pathname });
  }
  onCouponChange = coupon => {
    this.setState({ coupon });
  };
  onAddressSelect = async selectedAddress => {
    const { history } = this.props;
    if (!selectedAddress.raw) {
      history.push('/profile/addresses');
    } else this.setState({ selectedAddress });
  };
  onSelectPaymentMethod = async selectedProvider => {
    this.setState({ selectedProvider });
  };
  onSuccess = async result => {
    this.setState({ result });
  };
  render() {
    const {
      coupon,
      selectedAddress,
      selectedAddress: { raw = {} } = {},
      selectedProvider,
      result
    } = this.state;
    const {
      history,
      match: { params: { id: serviceId } = {} } = {}
    } = this.props;
    return (
      <Query
        query={GET_ME}
        variables={{ serviceId }}
        fetchPolicy={'cache-and-network'}
      >
        {({ loading, data }) => {
          if (loading) return <Loading />;
          const { shopByHostname, me, service } = data || {};
          const { id, credentials = [] } = shopByHostname || {};
          const { addresses = [], groups = [] } = me || {};
          if (!service) {
            history.replace('/services');
            return null;
          }
          const userGroups = groups.map(({ name }) => name);
          let userId;
          if (userGroups.length > 0 && userGroups.indexOf('Guests') < 0) {
            userId = me.id;
          }
          if (!result)
            return (
              <Fragment>
                <Helmet>
                  <title>
                    {auto2sc(service.name)} - {translate['meta.main.title']}
                  </title>
                </Helmet>
                <Container className={'py-5'}>
                  <Formik initialValues={{}} onSubmit={() => {}}>
                    {({
                      values,
                      onChange,
                      handleChange,
                      onSubmit,
                      handleSubmit
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <ServiceCard service={service} coupon={coupon} />
                        <AddressAndCouponSelectCard
                          onCouponChange={this.onCouponChange}
                          addresses={addresses}
                          onAddressChange={this.onAddressSelect}
                          disabled={selectedProvider}
                        />
                        {selectedAddress && (
                          <div className='custom-control custom-checkbox'>
                            <Input
                              required={true}
                              name={'agreed'}
                              value={values.agreed}
                              onChange={handleChange}
                              disabled={selectedProvider}
                              type={'checkbox'}
                              className={'custom-control-input'}
                              id={'cb-country'}
                            />
                            <Label
                              className={'custom-control-label'}
                              for={'cb-country'}
                            >
                              本人已閱讀並同意訂購相關服務之所有
                              <a href={'/pages/tnc'} target={'_blank'}>
                                條款及細則。
                              </a>
                            </Label>
                          </div>
                        )}
                        {values.agreed && (
                          <PaymentCard
                            service={service}
                            addressData={raw}
                            coupon={coupon}
                            shopId={id}
                            userId={userId}
                            credentials={credentials}
                            onSelectPaymentMethod={this.onSelectPaymentMethod}
                            onSuccess={this.onSuccess}
                          />
                        )}
                      </form>
                    )}
                  </Formik>
                </Container>
              </Fragment>
            );
          else return <SuccessCard history={history} result={result} />;
        }}
      </Query>
    );
  }
}
