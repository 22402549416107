import React from 'react';
import { Form, FormGroup, Button } from 'reactstrap';
import { Formik } from 'formik';
import FormText from './_formText';
import FormSelect from './_formSelect';
import FormCheckbox from './_formCheckbox';
import FormTextarea from './_formTextarea';
import FormHTML from './_formHTML';
import Loading from '../loading';

export default ({ onSubmit, inputs = [], submitText = 'Submit' }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={inputs.reduce(
      (reducer, { name, type, display }) =>
        display && type === 'CHECKBOX'
          ? { ...reducer, [name]: false }
          : reducer,
      {}
    )}
  >
    {({ values, handleSubmit, handleChange, isSubmitting }) => (
      <Form onSubmit={handleSubmit}>
        {inputs.map((input, i) => {
          const { name, type, display } = input;
          if (!display) return null;
          switch (type) {
            case 'TEXT':
              return (
                <FormText
                  {...input}
                  key={i}
                  value={values[name]}
                  onChange={handleChange}
                />
              );
            case 'SELECT':
              return (
                <FormSelect
                  {...input}
                  key={i}
                  value={values[name]}
                  onChange={handleChange}
                />
              );
            case 'CHECKBOX':
              return (
                <FormCheckbox
                  {...input}
                  key={i}
                  value={values[name]}
                  onChange={handleChange}
                />
              );
            case 'TEXTAREA':
              return (
                <FormTextarea
                  {...input}
                  key={i}
                  value={values[name]}
                  onChange={handleChange}
                />
              );
            case 'HTML':
              return <FormHTML {...input} key={i} />;
            default:
              return null;
          }
        })}
        {isSubmitting ? (
          <Loading />
        ) : (
          <FormGroup className={'text-center mt-4'}>
            <Button type={'submit'} color={'primary'}>
              {submitText}
            </Button>
          </FormGroup>
        )}
      </Form>
    )}
  </Formik>
);
