import React, { Fragment } from 'react';
import moment from 'moment';

export default ({ createdAt, name, href, onClick }) => (
  <Fragment>
    <dt>{moment(createdAt).format('YYYY 年 M 月 D 日')}</dt>
    <dd>
      <a
        href={`/articles/${href}`}
        onClick={e => {
          e.preventDefault();
          onClick && onClick();
        }}
      >
        {name}
      </a>
    </dd>
  </Fragment>
);
