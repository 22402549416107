import React from 'react';
import WeiXin from '../assets/weixin.png';

export default () => {
  return (
    <div className={'container'}>
      <img alt='WeiXin' className={'justify-content-center'} src={WeiXin} />
    </div>
  );
};
