import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingCart,
  faSearch,
  faFire,
  faChevronLeft,
  faTrashAlt,
  faPlus,
  faList,
  faChevronRight,
  faHeart,
  faUsdCircle,
  faHandHoldingUsd,
  faMapMarkerAlt,
  faWallet,
  faShoePrints,
  faBox,
  faTruck,
  faUser,
  faTicketAlt,
  faCreditCard,
  faMinus,
  faCaretDown,
  faBars,
  faHome,
  faTimes,
  faEyeDropper,
  faUserAlt,
  faHeartbeat,
  faStethoscope,
  faShareAlt,
  faMapMarkedAlt,
  faPhone,
  faFax,
  faEnvelope,
  faClock,
  faCreditCardBlank,
  faCheckCircle,
  faCalendarAlt,
  faLongArrowDown,
  faArrowRight,
  faCheck,
  faTag
} from '@fortawesome/pro-regular-svg-icons';
import {
  faFacebookSquare,
  faWeibo,
  faWeixin,
  faAlipay
} from '@fortawesome/free-brands-svg-icons';

export const Icon = ({ icon, ...props }) => {
  const icons = {
    faArrowRight,
    faCheck,
    faLongArrowDown,
    faCalendarAlt,
    faCheckCircle,
    faCreditCardBlank,
    faAlipay,
    faClock,
    faEnvelope,
    faFax,
    faPhone,
    faMapMarkedAlt,
    faShareAlt,
    faStethoscope,
    faHeartbeat,
    faUserAlt,
    faEyeDropper,
    faWeixin,
    faWeibo,
    faFacebookSquare,
    faShoppingCart,
    faSearch,
    faFire,
    faChevronLeft,
    faTrashAlt,
    faPlus,
    faList,
    faChevronRight,
    faHeart,
    faUsdCircle,
    faHandHoldingUsd,
    faMapMarkerAlt,
    faWallet,
    faShoePrints,
    faBox,
    faTruck,
    faUser,
    faTicketAlt,
    faCreditCard,
    faMinus,
    faCaretDown,
    faBars,
    faHome,
    faTimes,
    faTag
  };

  return <FontAwesomeIcon icon={icons[icon]} {...props} />;
};
