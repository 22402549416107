import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { errorParser } from '../../shared/errorParser';
import Loading from '../../components/loading';
import translate from '../../shared/translate';
import { Button } from 'reactstrap';

export default class WechatBridgePaymentWidget extends Component {
  state = {
    error: false
  };
  async componentDidMount() {
    await this.pay();
  }

  onRetryClick = async () => {
    this.setState({ error: false });
    await this.pay();
  };
  pay = async () => {
    const { token, onSuccess } = this.props;
    try {
      if (!this.checkWechatIsReady()) {
        throw new Error('Please Login with WeChat Browser.');
      }
      const inst = this;
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        JSON.parse(token),
        function(res = {}) {
          const msg = res.err_msg || res.errMsg;
          if (/ok/.test(msg)) {
            onSuccess && onSuccess(res);
          } else {
            inst.setState({ error: true });
          }
        }
      );
    } catch (e) {
      toast.error(errorParser(e));
      this.setState({ error: true });
    }
  };
  checkWechatIsReady = () => {
    return typeof window.WeixinJSBridge !== 'undefined';
  };
  render() {
    const { onReturn } = this.props;
    const { error } = this.state;
    if (error)
      return (
        <Fragment>
          <p className={'lead'}>{translate['payment_error_msg']}</p>
          <Button
            outline={true}
            className={'px-3 mr-3'}
            onClick={this.onRetryClick}
          >
            <span>{translate['retry']}</span>
          </Button>
          <Button outline={true} className={'px-3'} onClick={onReturn}>
            <span>{translate['return']}</span>
          </Button>
        </Fragment>
      );
    return (
      <div
        className={`d-flex justify-content-center align-items-center flex-column py-5`}
      >
        <Loading />
        <h4>微信支付中</h4>
      </div>
    );
  }
}

WechatBridgePaymentWidget.propTypes = {
  onSuccess: PropTypes.func,
  checkoutId: PropTypes.string,
  onReturn: PropTypes.func
};
