import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Loading from '../../../components/loading';
import moment from 'moment';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { client } from '../../../shared/apollo';
import translate from '../../../shared/translate';
import _ from 'lodash';
import { errorParser } from '../../../shared/errorParser';
import { Helmet } from 'react-helmet';

const ME = gql`
  query {
    me {
      id
      credentials {
        id
        identity
      }
      attributes {
        key
        value
      }
      createdAt
    }
  }
`;
const UPDATE_ME = gql`
  mutation($id: ID!, $input: UserInput!) {
    userUpdate(id: $id, input: $input) {
      id
    }
  }
`;

export default class extends Component {
  logout = async () => {
    const { history } = this.props;
    try {
      toast.success(translate['logout_success']);
      localStorage.removeItem('token');
      await client.resetStore();
      history.replace('/login');
    } catch (e) {
      toast.error(errorParser(e));
    }
  };
  submit = async (values, { setSubmitting }) => {
    try {
      await client.mutate({
        mutation: UPDATE_ME,
        variables: {
          id: values.id,
          input: {
            attributes: Object.keys(values)
              .filter(key => key !== 'id')
              .map(key => ({
                key,
                value: values[key]
              }))
          }
        }
      });
      await client.resetStore();
      toast.success(translate['update_success']);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
    }
  };
  render() {
    const { history } = this.props;
    return (
      <div className={'col-md-6 offset-md-3'}>
        <Helmet>
          <title>
            {translate['menu_profile']} - {translate['meta.main.title']}
          </title>
        </Helmet>
        <Query query={ME}>
          {({ loading, data }) => {
            if (loading) return <Loading />;
            try {
              const {
                me: {
                  id,
                  attributes,
                  credentials: [{ identity }],
                  createdAt
                }
              } = data;

              return (
                <Formik
                  onSubmit={this.submit}
                  initialValues={{
                    ..._.mapValues(_.keyBy(attributes, 'key'), 'value'),
                    id
                  }}
                >
                  {({ values, handleSubmit, handleChange, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className={'row'}>
                        <Label className={'col-sm-3 col-form-label'}>
                          {translate['email_address']}
                        </Label>
                        <div className={'col-sm'}>
                          <Input
                            defaultValue={identity}
                            plaintext={true}
                            disabled={true}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup className={'row'}>
                        <Label className={'col-sm-3 col-form-label'}>
                          {translate['name']}
                        </Label>
                        <div className={'col-sm'}>
                          <Input
                            value={values.name}
                            required={true}
                            name={'name'}
                            onChange={handleChange}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup className={'row'}>
                        <Label className={'col-sm-3 col-form-label'}>
                          {translate['join_date']}
                        </Label>
                        <div className={'col-sm'}>
                          <Input
                            defaultValue={moment(createdAt).format(
                              'MMM D, YYYY'
                            )}
                            plaintext={true}
                            disabled={true}
                          />
                        </div>
                      </FormGroup>

                      {isSubmitting ? (
                        <Loading />
                      ) : (
                        <FormGroup className={'text-center'}>
                          <Button
                            type={'submit'}
                            outline={true}
                            className={'px-5 mr-1'}
                          >
                            {translate['save']}
                          </Button>
                          <Button color={'danger'} onClick={this.logout}>
                            {translate['logout']}
                          </Button>
                        </FormGroup>
                      )}
                    </Form>
                  )}
                </Formik>
              );
            } catch (e) {
              history.replace('/login', {
                referer: history.location.pathname
              });
              return null;
            }
          }}
        </Query>
      </div>
    );
  }
}
