import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import ServicesList from './_servicesList';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Loading from '../../components/loading';
import translate from '../../shared/translate';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

const GET_SERVICES = gql`
  query {
    node: shopByHostname {
      id
      services(otherFilter: { active: true, display: true }) {
        id
        name
        description
        images(offset: { limit: 1 })
        group
        active
        items {
          productVariant {
            id
            price
          }
        }
        price
      }
    }
  }
`;

export default () => {
  return (
    <Fragment>
      <Helmet>
        <title>
          {translate['menu_service']} - {translate['meta.main.title']}
        </title>
      </Helmet>
      <Container className={'container-fluid my-3'}>
        <Query query={GET_SERVICES}>
          {({ loading, data = {} }) => {
            if (loading) return <Loading />;
            const { node } = data;
            const { services = [] } = node || {};

            const activeServices = _.sortBy(
              services.filter(({ active }) => !!active),
              'name'
            );

            if (activeServices.length === 0)
              return (
                <div className={'text-center py-3'}>
                  <p className={'lead font-italic text-muted'}>
                    {translate['no_service_message']}
                  </p>
                </div>
              );
            return <ServicesList services={activeServices} />;
          }}
        </Query>
      </Container>
    </Fragment>
  );
};
