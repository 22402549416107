import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ContactBlock from './contactBlock';
import SocialMedia from './socialMedia';
import { ResponsiveImage } from './headerNav';
import GoogleMapWrapper from './googleMapWrapper';
import Copyright from './copyright';
import LanguageSelector from './languageSelector';
import translate from '../shared/translate';
import ErrorBoundary from './ErrorBoundary';

export default ({
  name,
  copyrightName,
  address,
  phone1Prefix,
  phone1,
  phone2Prefix,
  phone2,
  faxPrefix,
  fax,
  emailPrefix,
  email,
  facebook,
  weibo,
  weixin,
  imageLink = [],
  onLanguageChange
}) => (
  <Container className={'mt-3 mt-sm-0'}>
    <Row>
      <Col>
        <ContactBlock
          name={name}
          address={address}
          phone1Prefix={phone1Prefix}
          phone1={phone1}
          phone2Prefix={phone2Prefix}
          phone2={phone2}
          faxPrefix={faxPrefix}
          fax={fax}
          emailPrefix={emailPrefix}
          email={email}
        />
        <div
          className={
            'ml-sm-3 mb-3 ml-md-5 d-flex justify-content-center justify-content-md-start'
          }
        >
          <div className={'mr-2 d-sm-none d-block'}>
            <LanguageSelector
              translate={translate}
              onChange={onLanguageChange}
            />
          </div>
          <div className={'col-md col-auto'}>
            <SocialMedia
              facebook={facebook}
              weibo={weibo}
              weixin={weixin}
              size={'2x'}
            />
          </div>
        </div>
        <div
          className={
            'ml-sm-3 ml-md-5 d-flex justify-content-center justify-content-md-start'
          }
        >
          {imageLink.length > 0 &&
            imageLink.map(({ href, target = '_blank', alt, src }, i) => (
              <a
                key={i}
                href={href}
                target={target}
                className={i > 0 ? 'ml-2 ml-xl-5' : ''}
              >
                <ResponsiveImage alt={alt} className={'img-fluid'} src={src} />
              </a>
            ))}
        </div>
      </Col>
      <Col className={'d-none d-md-flex'}>
        {!!window['google'] && <GoogleMapWrapper />}
      </Col>
    </Row>
    <Copyright name={copyrightName} />
  </Container>
);
