import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Button } from 'reactstrap';
import { injectStripe, Elements, CardElement } from 'react-stripe-elements';
import './style.scss';
import Loading from '../loading';

const InjectedForm = injectStripe(
  ({ stripe, onSubmit, submitText = 'Submit', isSubmitting }) => (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onSubmit && onSubmit(stripe);
      }}
      className={'text-left'}
    >
      <FormGroup>
        <CardElement className={'form-control'} />
      </FormGroup>
      {isSubmitting ? (
        <Loading />
      ) : (
        <FormGroup className={'text-center'}>
          <Button
            type={'submit'}
            color={'dark'}
            outline={true}
            className={'px-5'}
          >
            {submitText}
          </Button>
        </FormGroup>
      )}
    </Form>
  )
);

const StripePaymentForm = props => (
  <Elements>
    <InjectedForm {...props} />
  </Elements>
);

StripePaymentForm.propTypes = {
  stripe: PropTypes.any,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  submitText: PropTypes.string
};

export default StripePaymentForm;
