import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { toNumberFormat } from '../../shared';

export default ({ service: { name, description, price = 0 } = {}, coupon }) => {
  if (!!coupon) {
    const { discount, discountType, amountBeforeDiscount } = coupon || {};
    if (discountType === 'AMOUNT' && price > amountBeforeDiscount) {
      price = price - discount;
    } else if (discountType === 'PERCENTAGE') {
      price = (price - 0) * (discount - 0);
    }
  }

  return (
    <Card className={'my-3'}>
      <CardBody>
        <Row>
          <Col>
            <p className={'lead'}>{name}</p>
            <p>{description}</p>
          </Col>
          <div className={'col-md-auto col-12 lead text-right'}>
            ${toNumberFormat(price, true)}
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};
