import React from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { Icon } from '../../components/iconRender';
import translate from '../../shared/translate';

export default ({ history }) => (
  <Card className={'my-3'}>
    <CardBody className={'text-center'}>
      <Icon icon={'faCheckCircle'} size={'10x'} className={'text-success'} />
      <h1 className={'my-3'}>{translate['payment_success']}</h1>
      <p className={'lead'}>{translate['email_will_sent_automatically']}</p>
      <Button
        outline={true}
        href={'/profile/orders'}
        onClick={e => {
          e.preventDefault();
          history.replace('/profile/orders');
        }}
      >
        {translate['view_order']}
      </Button>
    </CardBody>
  </Card>
);
