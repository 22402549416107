import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';

export default ({ items }) => (
  <Fragment>
    <Nav className={'nav-arrows justify-content-center d-flex flex-nowrap'}>
      {items.map(({ title, active, href }, i) => (
        <NavItem key={i} className={"d-flex flex-direction-column-reverse"}>
          <Link to={href} className={`nav-link px-3 ${active ? 'active' : ''}`}>
            {title}
          </Link>
        </NavItem>
      ))}
    </Nav>
    <hr className={'mt-0'} />
  </Fragment>
);
