import React from 'react';
import moment from 'moment';

const {
  REACT_APP_VERSION = 'dev',
} = process.env;

export default ({
  policyMark = '©',
  year = moment().format('YYYY'),
  name = 'ABC Ltd.',
  statement = 'All rights reserved.'
}) => (
  <div className={'text-center small mt-2'}>
    <span>
      {policyMark} {year} {name}
    </span>
    <span className='d-none d-sm-inline-block'>{statement}</span>
    <div className="text-muted">{REACT_APP_VERSION}</div>
  </div>
);
