import React, { Fragment } from 'react';
import { Row, Button } from 'reactstrap';
import moment from 'moment';
import translate from '../../../shared/translate';

export default ({
  id,
  name,
  createdAt,
  active,
  valid,
  status,
  slots = [],
  showButton = true,
  history,
  orderItem = {}
}) => {
  let bookingDate =
    slots[0] &&
    moment(slots[0].startedAt).format(translate['moment_format_date']);
  const { order } = orderItem || {};
  const { billingAddress } = order || {};
  const { person, tel } = billingAddress || {};
  return (
    <Fragment>
      <Row>
        <div className={'col-sm'}>
          <div className={'media no-gutters'}>
            <div className={'media-body'}>
              <p className={'lead'}>{name}</p>
              {person && <div className={'small'}>預約人：{person}</div>}
              {tel && (
                <div className={'small'}>
                  電話　：{tel}
                </div>
              )}
              <div className={'small'}>
                {translate['purchase_date']}：
                {moment(createdAt).format(translate['moment_format_date'])}
              </div>
              <div className={'small'}>
                {translate['activate_date']}：
                {moment(createdAt)
                  .add(active - 0, 'day')
                  .format(translate['moment_format_date'])}
              </div>
              <div className={'small'}>
                {translate['activate_to']}：
                {moment(createdAt)
                  .add(valid - 0, 'day')
                  .format(translate['moment_format_date'])}
              </div>
              {bookingDate && (
                <div className={'text-success'}>
                  {translate['booking_date']}：{bookingDate}
                </div>
              )}
            </div>
          </div>
        </div>
        {showButton && __renderButton()}
      </Row>
      <hr />
    </Fragment>
  );

  function __renderButton() {
    const expired = moment(createdAt)
      .add(valid - 0, 'day')
      .isBefore(moment());
    if (expired)
      return (
        <small className={'col-sm-auto text-center mt-3 mt-sm-0'}>
          {translate['service_expired']}
        </small>
      );

    return (
      <div className={'col-sm-auto mt-3 mt-sm-0'}>
        {{
          PENDING: (
            <Button
              className={'btn-sm'}
              outline={true}
              href={`/profile/events/${id}`}
              onClick={e => {
                e.preventDefault();
                history.push(`/profile/events/${id}`);
              }}
            >
              {translate['booking_now']}
            </Button>
          )
        }[status] || (
          <Button
            className={'btn-sm'}
            outline={true}
            href={`/profile/events/${id}`}
            onClick={e => {
              e.preventDefault();
              history.push(`/profile/events/${id}`);
            }}
          >
            {translate['view_detail']}
          </Button>
        )}
      </div>
    );
  }
};
