import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import moment from 'moment';
import translate from '../../../shared/translate';

export default ({
  createdAt,
  total_amount,
  buyer,
  orderId,
  items,
  history
}) => (
  <Card className={'mb-3'}>
    <CardHeader>
      <div className={'row flex-md-row'}>
        <div className={'col-md-2 col-6 d-flex flex-column'}>
          <small>{translate['purchase_date']}</small>
          <p className={'m-0'}>{moment(createdAt).format('MMM D, YYYY')}</p>
        </div>
        <div className={'col-md-2 col-6 d-flex flex-column'}>
          <small>{translate['order_total_amount']}</small>
          <p className={'m-0'}>
            {total_amount.amount.toLocaleString(undefined, {
              currency: total_amount.locale,
              style: 'currency'
            })}
          </p>
        </div>
        <div className={'col-md col-6 d-flex flex-column'}>
          <small>{translate['person']}</small>
          <p className={'m-0'}>{buyer.person || ''}</p>
        </div>
        <div
          className={
            'col-md-2 col-sm-6 col align-items-md-end d-flex flex-column'
          }
        >
          <small>{translate['order_id']}</small>
          <p className={'m-0'}>
            <a
              href={`/profile/orders/${orderId}`}
              onClick={e => {
                e.preventDefault();
                history.push(`/profile/orders/${orderId}`);
              }}
              className={'small text-truncate w-100'}
            >
              {orderId}
            </a>
          </p>
        </div>
      </div>
    </CardHeader>
    <CardBody>
      {(items || []).map(({ description, unitPrice, productVariant }, i) => {
        const isServiceItem = !productVariant;
        return (
          <Row
            key={i}
            className={
              isServiceItem ? 'lead font-weight-bold' : 'small text-muted my-1'
            }
          >
            <div className={'col-sm-6 col-12'}>
              {description && <Fragment>{description}</Fragment>}
            </div>
            <div className={'text-right col-sm-6 col-12'}>
              {unitPrice.amount.toLocaleString(undefined, {
                currency: unitPrice.locale,
                style: 'currency'
              })}
            </div>
          </Row>
        );
      })}
    </CardBody>
  </Card>
);
