import React, { Component, Fragment } from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import HeaderNav from '../../components/headerNav';
import FooterNav from '../../components/footerNav';
import FooterContact from '../../components/footerContact';
import translate from '../../shared/translate';
import { client } from '../../shared/apollo';
import HomePage from '../homePage';
import CustomPage from '../customPage';
import Articles from '../articles';
import Services from '../services';
import Login from '../login';
import Register from '../register';
import ResetPassword from '../resetPassword';
import Profile from '../profile';
import Checkout from '../checkout';
import WeixinImg from '../../components/weixin';
import WechatLogin from '../wechatLogin';
import Promotions from '../promotions';
import Error from '../error';
import PromotionView from '../promotions/view';
import ForgetPassword from '../forgetPassword';
import { errorParser } from '../../shared/errorParser';
import ErrorBoundary from '../../components/ErrorBoundary';

const GET_SHOP_WECHAT_URL = gql`
  query {
    shopByHostname {
      id
      wechatUrl
    }
  }
`;

const routes = [
  { path: '/', component: HomePage, exact: true },

  { path: '/services', component: Services, exact: true },
  { path: '/login', component: Login, exact: true },
  { path: '/register', component: Register, exact: true },
  { path: '/reset_password', component: ResetPassword, exact: true },
  { path: '/profile', component: Profile },
  { path: '/wechat_login', component: WechatLogin },
  { path: '/checkout/:id', component: Checkout },
  { path: '/promotions/:href', component: PromotionView },
  { path: '/promotions', component: Promotions },
  { path: '/pages/:href?', component: CustomPage },
  { path: '/articles/:href?', component: Articles },
  { path: '/assets/images/hcwechat.jpg', component: WeixinImg },
  { path: '/forget_password', component: ForgetPassword },
  { component: Error }
];

export default class extends Component {
  async componentDidMount() {
    const {
      location: { pathname }
    } = this.props;
    try {
      const isLogined = !!localStorage.getItem('token');
      const isWechatBrowser = /micromessenger/i.test(navigator.userAgent);
      const isProcessingWechatLogin = pathname === '/wechat_login';
      if (!isWechatBrowser || isLogined || isProcessingWechatLogin) return;
      const {
        data: {
          shopByHostname: { wechatUrl }
        }
      } = await client.query({
        query: GET_SHOP_WECHAT_URL
      });
      wechatUrl && (window.location = wechatUrl);
    } catch (e) {
      toast.error(errorParser(e));
    }
  }

  onMenuClick = (href) => {
    const { history } = this.props;
    history.push(href);
  };

  render() {
    return (
      <ErrorBoundary>
        <Container fluid={true} className={'py-sm-3 d-flex flex-column h-100'}>
          <Helmet>
            <title>{translate['meta.main.title']}</title>
            <meta
              name='description'
              content={translate['meta.main.description']}
            />
          </Helmet>
          {this.renderHeader()}
          <div className={'flex-grow-1'}>{this.renderSwitcher()}</div>
          {this.renderFooter()}
        </Container>
      </ErrorBoundary>
    );
  }

  renderHeader() {
    return (
      <HeaderNav
        onMenuClick={this.onMenuClick}
        topBarProps={{
          onLanguageChange: async (lang) => {
            localStorage.setItem('lang', lang);
            window.location.reload();
          },
          b1: translate['contact.company_name'],
          b2: `${translate['phone']}：${translate['contact.tel']}`,
          b3: `${translate['china_contact_number']}：${translate['contact.tel.cn']}`,
          facebook: translate['contact.facebook'],
          weibo: translate['contact.weibo'],
          weixin: translate['contact.weixi'],
          translate
        }}
        pages={[
          {
            title: translate['menu_aboutus'],
            href: '/pages/aboutus'
          },
          {
            title: translate['menu_service'],
            href: '/services'
          },
          {
            title: translate['menu_checkup'],
            href: '/pages/checkup'
          },
          {
            title: translate['menu_contact'],
            href: '/pages/contact'
          },
          {
            title: translate['menu_member'],
            href: '/profile'
          },
          {
            title: translate['menu_promotion'],
            href: '/promotions'
          }
        ]}
        imageLink={[
          {
            href: 'http://www.vaccine.hk',
            src: require('../../assets/vaccine.hk.jpg')
          },
          {
            href: 'http://www.checkup.hk',
            src: require('../../assets/checkup.hk.jpg')
          }
        ]}
      />
    );
  }
  renderSwitcher() {
    return (
      <Switch>
        {routes.map(({ exact, path, component }, i) => {
          return (
            <Route exact={exact} path={path} component={component} key={i} />
          );
        })}
      </Switch>
    );
  }
  renderFooter() {
    return (
      <Fragment>
        <FooterNav
          onClick={this.onMenuClick}
          items={[
            { text: translate['menu_aboutus'], href: '/pages/aboutus' },
            { text: translate['menu_service'], href: '/services' },
            { text: translate['menu_checkup'], href: '/pages/checkup' },
            { text: translate['menu_contact'], href: '/pages/contact' },
            {
              text: translate['menu_member'],
              href: '/profile'
            },
            {
              text: translate['menu_promotion'],
              href: '/promotions'
            }
          ]}
        />
        <FooterContact
          onLanguageChange={async (lang) => {
            localStorage.setItem('lang', lang);
            window.location.reload();
          }}
          name={translate['contact.company_name']}
          copyrightName={translate['contact.copyrightName']}
          address={translate['contact.address']}
          phone1Prefix={`${translate['phone']}：`}
          phone1={translate['contact.tel']}
          phone2Prefix={`${translate['china_contact_number']}：`}
          phone2={translate['contact.tel.cn']}
          fax={translate['contact.fax']}
          emailPrefix={`${translate['email']}：`}
          email={translate['contact.email']}
          facebook={translate['contact.facebook']}
          weibo={translate['contact.weibo']}
          weixin={translate['contact.weixi']}
          imageLink={[
            {
              href: 'http://www.vaccine.hk',
              src: require('../../assets/vaccine.hk.jpg')
            },
            {
              href: 'http://www.checkup.hk',
              src: require('../../assets/checkup.hk.jpg')
            }
          ]}
        />
      </Fragment>
    );
  }
}
