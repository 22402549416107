import React from 'react';
import { Col, Row } from 'reactstrap';

export default ({ href, title, remark, onClick }) => (
  <a
    href={href}
    onClick={e => {
      e.preventDefault();
      onClick && onClick();
    }}
    className={'bg-rr list-group-item list-group-item-action rounded-0'}
  >
    <Row>
      <Col>{title}</Col>
      <Col className={'col-auto text-right'}>
        <pre className={'small m-0'}>{remark}</pre>
      </Col>
    </Row>
  </a>
);
