import React from 'react';

export default ({ src, style, children, ...props }) => (
  <div
    {...props}
    style={{ ...styles.bgDiv, backgroundImage: `url(${src})`, ...style }}
  >
    {children}
  </div>
);

const styles = {
  bgDiv: {
    position: 'relative',
      backgroundSize: "cover"
  }
};
