import React from 'react';
import { ListGroup } from 'reactstrap';
import StyledTitle from './styledTitle';
import BgRRListItem from './bgRRListItem';

export default ({
  height = 450,
  title,
  list = [],
  onClick,
  listItemComponent = BgRRListItem
}) => (
  <div>
    <StyledTitle type={'dark'} text={title} />
    <ListGroup
      className={'rounded-0 overflow-auto'}
      style={{ maxHeight: height }}
    >
      {list.map((props, i) => {
        let ListItemComponent = listItemComponent;
        return (
          <ListItemComponent
            key={i}
            {...props}
            onClick={() => onClick && onClick(props)}
          />
        );
      })}
    </ListGroup>
  </div>
);
