import React, { Component, Fragment } from 'react';
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Loading from '../../components/loading';
import StyledNav from '../../components/styledNav';
import gql from 'graphql-tag';
import { client } from '../../shared/apollo';
import translate from '../../shared/translate';
import { errorParser } from '../../shared/errorParser';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const LOGIN = gql`
  mutation($identity: String!, $secret: String!) {
    sessionCreate(identity: $identity, secret: $secret)
  }
`;

export default class extends Component {
  login = async ({ identity, secret }) => {
    const { data: { sessionCreate: token } = {} } = await client.mutate({
      mutation: LOGIN,
      variables: {
        identity,
        secret
      }
    });
    return token;
  };
  submit = async (values, { setSubmitting }) => {
    const { history, location: { state: { referer } = {} } = {} } = this.props;
    try {
      let token = await this.login({
        identity: values.email,
        secret: values.password
      });
      localStorage.setItem('token', token);
      await client.resetStore();
      toast.success(translate['login_success']);

      history.replace(referer || '/profile');
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
    }
  };
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>
            {translate['login']} - {translate['meta.main.title']}
          </title>
        </Helmet>
        <Container className={'py-5'}>
          <StyledNav
            items={[
              {
                title: translate['login'],
                href: '/login',
                active: true
              },
              {
                title: translate['new_register'],
                href: '/register'
              }
            ]}
          />
          <Row>
            <div className={'col-md-6 offset-md-3'}>
              <Formik onSubmit={this.submit}>
                {({ values, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label htmlFor={'email'}>
                        {translate['email_address']}
                      </Label>
                      <Input
                        required={true}
                        name={'email'}
                        value={values.email}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor={'password'}>
                        {translate['login_password']}
                      </Label>
                      <Input
                        required={true}
                        value={values.password}
                        name={'password'}
                        type={'password'}
                        placeholder={translate['password']}
                        onChange={handleChange}
                      />
                      <div className={'text-right mt-2'}>
                        <Link to={'/forget_password'} className={'small'}>
                          {translate['forget_password']}
                        </Link>
                      </div>
                    </FormGroup>
                    {isSubmitting ? (
                      <Loading />
                    ) : (
                      <FormGroup className={'text-center'}>
                        <Button
                          type={'submit'}
                          color={'primary'}
                          className={'px-5'}
                        >
                          {translate['login']}
                        </Button>
                      </FormGroup>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
