import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Button, Label } from 'reactstrap';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { errorParser } from '../../../../shared/errorParser';
import { client } from '../../../../shared/apollo';
import SingleSelector from '../../../../components/singleSelector';
import StyledDatePicker from '../../../../components/styledDatePicker';
import moment from 'moment';
import VenueCard from './_venueCard';
import Query from 'react-apollo/Query';
import translate from '../../../../shared/translate';
import Loading from '../../../../components/loading';

const PREPARE_QUERY = gql`
  query {
    __type(name: "District") {
      name
      districts: enumValues {
        value: name
        label: description
      }
    }
  }
`;
const GET_SLOT = gql`
  query($id: ID!, $filter: AvailableVenuesFilterInput!) {
    node(id: $id) {
      id
      ... on Event {
        availableVenues(filter: $filter) {
          id
          name
          address {
            tel
            email
            lines
            country
          }
          availableSlots {
            id
            startedAt
            startedThru
            capacity
            recurrence
          }
        }
      }
    }
  }
`;
const EVENT_RESERVE = gql`
  mutation($id: ID!, $input: [ID!]!) {
    eventReserve(id: $id, input: $input) {
      id
      status
      slots {
        id
      }
    }
  }
`;

export default class BookingForm extends Component {
  state = {
    minDate: undefined,
    maxDate: undefined,
    venue: undefined,
    district: undefined,
    districts: [],
    time: undefined,
    ready: false,
    isSubmitting: false
  };
  async componentDidMount() {
    const {
      event: { createdAt, active, valid }
    } = this.props;

    const now = moment().toDate();
    let minDate = moment(createdAt)
      .add(active - 0, 'day')
      .toDate();
    let maxDate = moment(createdAt)
      .add(valid - 0, 'day')
      .toDate();
    try {
      const {
        data: {
          __type: { districts }
        }
      } = await client.query({
        query: PREPARE_QUERY
      });
      this.setState({
        minDate: minDate > now ? minDate : now,
        maxDate,
        time: minDate > now ? minDate : now,
        districts,
        ready: true
      });
    } catch (e) {
      toast.error(errorParser(e));
    }
  }
  onDistrictChange = district => {
    this.setState({ district });
  };
  onTimeChange = time => {
    this.setState({ time });
  };
  onVenueSelect = venue => {
    this.setState({ venue });
  };
  submit = async () => {
    const { event: { id } = {}, onSuccess } = this.props;
    const { venue } = this.state;
    if (!venue) return;
    this.setState({ isSubmitting: true });
    const { availableSlots = [] } = venue;
    try {
      await client.mutate({
        mutation: EVENT_RESERVE,
        variables: { id, input: availableSlots.map(slot => slot.id) }
      });
      toast.success(translate['reserve_success']);
      onSuccess && onSuccess();
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      this.setState({ isSubmitting: false });
    }
  };
  render() {
    const { ready } = this.state;
    if (!ready) return null;

    return (
      <div>
        <h2>{translate['search_reservable_venue']}</h2>
        <Form className={'my-3'}>
          {this.renderDistrictPicker()}
          {this.renderDatePicker()}
          {this.renderVenuePicker()}
        </Form>
      </div>
    );
  }
  renderDistrictPicker() {
    const { districts, district, isSubmitting } = this.state;
    return (
      <FormGroup>
        <Label>{translate['reserve_district']}</Label>
        <SingleSelector
          isDisabled={isSubmitting}
          placeholderText={translate['please_select_district']}
          options={districts}
          onChange={this.onDistrictChange}
          value={district}
        />
      </FormGroup>
    );
  }
  renderDatePicker() {
    const { time, minDate, maxDate, isSubmitting } = this.state;
    return (
      <FormGroup>
        <Label>{translate['reserve_datetime']}</Label>
        <StyledDatePicker
          disabled={isSubmitting}
          placeholderText={translate['please_select_datetime']}
          dateFormat={'MMMM d, yyyy  h:mm aa'}
          selected={time}
          onChange={this.onTimeChange}
          minDate={minDate}
          maxDate={maxDate}
          showTimeSelect
          timeFormat={'h:mm aa'}
          timeIntervals={15}
          timeCaption={translate['time_duration']}
        />
      </FormGroup>
    );
  }
  renderVenuePicker() {
    const {
      event: { id }
    } = this.props;
    const { district, time, venue, isSubmitting } = this.state;
    if (!district || !time) return null;

    let startedAt = moment(time).toISOString();
    return (
      <Query
        query={GET_SLOT}
        fetchPolicy={'no-cache'}
        variables={{ id, filter: { districts: district.value, startedAt } }}
      >
        {({ loading, data }) => {
          if (loading) {
            if (venue) this.setState({ venue: undefined });
            return <Loading />;
          }
          const { node } = data || {};
          const { availableVenues = [] } = node || {};
          return (
            <Fragment>
              <FormGroup>
                <Label>{translate['reservable_venue']}</Label>
                {availableVenues.map((_venue, i) => (
                  <VenueCard
                    {..._venue}
                    disabled={isSubmitting}
                    selected={venue && _venue.id === venue.id}
                    key={i}
                    onClick={() => this.onVenueSelect(_venue)}
                  />
                ))}
                {availableVenues.length === 0 && (
                  <p className={'lead text-center'}>
                    {translate['no_venue_message']}
                  </p>
                )}
              </FormGroup>
              {venue && venue.id && (
                <Fragment>
                  {isSubmitting ? (
                    <Loading />
                  ) : (
                    <FormGroup className={'text-center'}>
                      <Button
                        className={'px-5'}
                        outline={true}
                        onClick={this.submit}
                      >
                        <span>{translate['submit']}</span>
                      </Button>
                    </FormGroup>
                  )}
                </Fragment>
              )}
            </Fragment>
          );
        }}
      </Query>
    );
  }
}
BookingForm.propTypes = {
  event: PropTypes.object,
  onSuccess: PropTypes.func
};
