import gql from 'graphql-tag';

export const GET_QUERY = gql`
  query($skip: Int, $limit: Int) {
    node: shopByHostname {
      id
      articles: customPages(
        otherFilter: { groups: ["articles"] }
        offset: { skip: $skip, limit: $limit }
      ) {
        id
        name
        href
        group
        createdAt
      }
      count: customPagesCount(otherFilter: { groups: ["articles"] })
    }
  }
`;
