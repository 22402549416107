import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export default ({
  value,
  name,
  description = '',
  required = true,
  onChange
}) => (
  <FormGroup check className={'mb-2'}>
    <Label check>
      <Input
        name={name}
        checked={value}
        type={'checkbox'}
        required={required}
        onChange={onChange}
      />{' '}
      {description || name}
    </Label>
  </FormGroup>
);
