import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from './iconRender';

export default class Rect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
  }

  onMouseOver() {
    this.setState({ hover: true });
  }

  onMouseOut() {
    this.setState({ hover: false });
  }

  render() {
    let { icon = '', text = '', backgroundColor, onClick } = this.props;
    let { hover } = this.state;
    let color = hover ? 'yellow' : 'white';
    return (
      <div
        className={'w-100 position-relative'}
        style={{ backgroundColor, cursor: 'pointer' }}
        onClick={e => {
          e.preventDefault();
          onClick && onClick();
        }}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      >
        <img
          alt={'rect'}
          style={{ opacity: 0 }}
          className='w-100 img-fluid'
          src={require('../assets/rect.jpg')}
        />
        <div className={'position-absolute'} style={styles.rectContent}>
          <Icon icon={icon} size={'2x'} color={color} />
          <br />
          <span className={'d-none d-md-block'} style={{ color }}>
            {text}
          </span>
        </div>
      </div>
    );
  }
}
Rect.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string
};

const styles = {
  rectContent: {
    color: '#ffffff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  }
};
