import translate from '../../shared/translate';

export default {
  MANUAL: {
    name: translate['pay_manual']
  },
  STRIPE_CONNECT_CUSTOM: {
    name: translate['pay_credit_card'],
    icon: 'faCreditCardBlank',
    bg: '#121212',
    providers: {
      desktop: 'STRIPE_CONNECT_CUSTOM',
      mobile: 'STRIPE_CONNECT_CUSTOM'
    },
    sortIndex: { sort: 5 }
  },
  STRIPE_CREDIT_CARD: {
    name: translate['pay_credit_card'],
    icon: 'faCreditCardBlank',
    bg: '#121212',
    providers: {
      desktop: 'STRIPE_CREDIT_CARD',
      mobile: 'STRIPE_CREDIT_CARD'
    },
    sortIndex: { sort: 3 }
  },
  CHINA_SMARTPAY_WECHAT_PAY_HK: {
    name: `${translate['pay_wechat']}`,
    icon: 'faWeixin',
    bg: '#22bc3c',
    providers: {
      desktop: 'CHINA_SMARTPAY_WECHAT_PAY_HK_SCAN',
      mobile: 'CHINA_SMARTPAY_WECHAT_PAY_HK_SCAN',
      wechat: 'CHINA_SMARTPAY_WECHAT_PAY_HK_BRIDGE'
    },
    sortIndex: { sort: 1 }
  },
  CHINA_SMARTPAY_ALIPAY_HK: {
    name: `${translate['pay_alipay']}`,
    icon: 'faAlipay',
    bg: '#26b8d7',
    providers: {
      desktop: 'CHINA_SMARTPAY_ALIPAY_HK_SCAN',
      mobile: 'CHINA_SMARTPAY_ALIPAY_HK_SCAN'
    },
    sortIndex: { sort: 0 }
  },
  SWIFTPASS_WECHAT_PAY: {
    name: translate['pay_wechat'],
    icon: 'faWeixin',
    bg: '#22bc3c',
    providers: {
      desktop: 'SWIFTPASS_WECHAT_PAY_SCAN',
      mobile: 'SWIFTPASS_WECHAT_PAY_SCAN',
      wechat: 'SWIFTPASS_WECHAT_PAY_BRIDGE'
    },
    sortIndex: { sort: 4 }
  },
  SWIFTPASS_ALIPAY: {
    name: `${translate['pay_alipay']}`,
    icon: 'faAlipay',
    bg: '#26b8d7',
    providers: {
      desktop: 'SWIFTPASS_ALIPAY_SCAN',
      mobile: 'SWIFTPASS_ALIPAY_SCAN'
    },
    sortIndex: { sort: 0 }
  }
};
