import 'react-app-polyfill/ie9';
import 'core-js/es6/map';
import 'core-js/es6/set';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { ApolloProvider } from 'react-apollo';
import './css/custom.scss';
import './index.css';
import { client } from './shared/apollo';
import * as Sentry from '@sentry/react';

const { REACT_APP_VERSION = 'dev' } = process.env;

Sentry.init({
  dsn: 'https://4f8ae03bc56a4257afc030e83f535dda:bd661151240d4269964e9f197b9785f6@o337863.ingest.sentry.io/5353393',
  beforeSend: (event) => {
    console.log('beforeSend', event);
    return event;
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);

registerServiceWorker();

localStorage.setItem('version', REACT_APP_VERSION);
