import React, { Fragment } from 'react';
import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import Loading from '../../../components/loading';
import Enum_paymentMethod from '../../checkout/_enum_paymentMethods';
import translate from '../../../shared/translate';
import { Helmet } from 'react-helmet';

const GET_QUERY = gql`
  query($id: ID) {
    node(id: $id) {
      id
      ... on Order {
        id
        coupons {
          id
          name
        }
        subtotal {
          amount
          locale
        }
        tax {
          amount
          locale
        }
        total {
          amount
          locale
        }
        discount
        discountType
        items {
          id
          description
          quantity
          unitPrice {
            amount
            locale
          }
          productVariant {
            id
          }
        }
        checkout {
          id
          payment {
            provider
          }
          attributes {
            key
            value
          }
        }
        invoices {
          id
          paymentMethod
        }
        attributes {
          key
          value
        }
      }
    }
  }
`;

export default ({
  match: {
    params: { id }
  },
  history
}) => (
  <Fragment>
    <Helmet>
      <title>
        {translate['menu_orders']} - {translate['meta.main.title']}
      </title>
    </Helmet>
    <Query query={GET_QUERY} variables={{ id }}>
      {({ loading, data: { node } = {} }) => {
        if (loading && !node) return <Loading />;
        const {
          subtotal: { locale = 'hkd', amount: subtotal = 0 } = {},
          tax: { amount: tax = 0 } = {},
          total: { amount: total = 0 } = {},
          coupons = [],
          items,
          checkout: { payment: { provider } = {}, attributes } = {}
        } = node || {};

        const discountAmount = Math.max(total - tax - subtotal, 0);

        let buyer = JSON.parse(
          (
            (attributes || []).find(attr => attr.key === 'buyer') || {
              value: '{}'
            }
          ).value || '{}'
        );
        let paymentMethod = Enum_paymentMethod[provider] || {};

        return (
          <Fragment>
            <Card className={'my-3'}>
              <CardBody className={'small row no-gutters'}>
                <div className={'col-sm mb-3 mb-sm-0'}>
                  <h6>{translate['person']}</h6>
                  <b>{buyer.person}</b>
                  {buyer.email && (
                    <a href={`mailto:${buyer.email}}`}>
                      <div>{buyer.email}</div>
                    </a>
                  )}
                  {buyer.tel && (
                    <a href={`tel:${buyer.tel}`}>
                      <div>{buyer.tel}</div>
                    </a>
                  )}
                  {buyer.lines &&
                    buyer.lines.map(
                      (line, i) => line && <div key={i}>{line}</div>
                    )}
                  {buyer.country && <div>{translate[buyer.country]}</div>}
                </div>
                <div className={'col-sm mb-3 mb-sm-0'}>
                  <h6>{translate['payment_method']}</h6>
                  <div>{paymentMethod.name}</div>
                </div>
                <div className={'col-sm mb-3 mb-sm-0'}>
                  <h6>{translate['order_brief']}</h6>
                  <Col>
                    <Row>
                      <b>{translate['sub_total']}</b>
                      <Col />
                      <span>
                        {subtotal.toLocaleString(undefined, {
                          currency: locale,
                          style: 'currency'
                        })}
                      </span>
                    </Row>
                    <Row>
                      <b>{translate['discount']}</b>
                      <Col />
                      <span>
                        -
                        {discountAmount.toLocaleString(undefined, {
                          currency: locale,
                          style: 'currency'
                        })}
                      </span>
                    </Row>
                    <Row>
                      <b>{translate['tax']}</b>
                      <Col />
                      <span>
                        {tax.toLocaleString(undefined, {
                          currency: locale,
                          style: 'currency'
                        })}
                      </span>
                    </Row>
                    <Row>
                      <b>{translate['total_amount']}</b>
                      <Col />
                      <b>
                        {total.toLocaleString(undefined, {
                          currency: locale,
                          style: 'currency'
                        })}
                      </b>
                    </Row>
                  </Col>
                </div>
              </CardBody>
            </Card>
            <Row className={'mb-3'}>
              {coupons.length > 0 && (
                <div className={'col-auto'}>
                  <Card className={'h-100'}>
                    <CardBody>
                      <p className={'lead font-weight-bold m-0'}>
                        {translate['coupon']}
                      </p>
                      {coupons.map(({ name } = {}, i) => (
                        <div key={i}>
                          <span className={'small'}>{name}</span>
                        </div>
                      ))}
                    </CardBody>
                  </Card>
                </div>
              )}
              <Col>
                <Card className={'h-100'}>
                  <CardBody>
                    {(items || []).map(
                      (
                        {
                          description,
                          unitPrice: { amount: unitPrice } = {},
                          productVariant
                        },
                        i
                      ) => {
                        const isServiceItem = !productVariant;
                        return (
                          <Row
                            key={i}
                            className={
                              isServiceItem
                                ? 'lead font-weight-bold'
                                : 'small text-muted my-2'
                            }
                          >
                            <div className={'col-sm-6 col-12'}>
                              <span>{description}</span>
                            </div>
                            <div className={'text-right col-sm-6 col-12'}>
                              <span>
                                {unitPrice.toLocaleString(undefined, {
                                  currency: locale,
                                  style: 'currency'
                                })}
                              </span>
                            </div>
                          </Row>
                        );
                      }
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className={'text-center'}>
              <Button
                outline={true}
                href={'/services'}
                onClick={e => {
                  e.preventDefault();
                  history.push('/services');
                }}
              >
                {translate['purchase_again']}
              </Button>
            </div>
          </Fragment>
        );
      }}
    </Query>
  </Fragment>
);
