import LocalizedStrings from 'react-localization';
const langs = require('./lang');

const langOptions = ['zh_hk', 'zh_cn'];

let formattedLags = langOptions.reduce((prev, option) => {
  prev[option] = Object.entries(langs).reduce((prev, [key, value]) => {
    prev[key] = value[option];
    return prev;
  }, {});
  return prev;
}, {});

let Strings = new LocalizedStrings(formattedLags);
localStorage.getItem('lang') &&
  Strings.setLanguage(localStorage.getItem('lang'));

export default Strings;
