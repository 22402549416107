import React from 'react';
import { Container, Col } from 'reactstrap';
import SocialMedia from './socialMedia';
import LanguageSelector from './languageSelector';

export default ({
  b1,
  b2,
  b3,
  facebook,
  weibo,
  weixin,
  translate,
  onLanguageChange
}) => (
  <Container>
    {b1 && (
      <small className={'col-auto text-dark font-weight-bold'}>
        <b>{b1}</b>
      </small>
    )}
    {b2 && (
      <small className={'col-auto d-none d-lg-block'}>
        <b>{b2}</b>
      </small>
    )}
    {b3 && (
      <small className='col-auto d-none d-lg-block'>
        <b>{b3}</b>
      </small>
    )}
    <Col />
    {translate && (
      <div className={'mr-2'}>
        <LanguageSelector translate={translate} onChange={onLanguageChange} />
      </div>
    )}
    <div
      className={
        'col-auto display-none display-md-block text-right pr-0 pr-md-3'
      }
    >
      <SocialMedia
        header={true}
        facebook={facebook}
        weibo={weibo}
        weixin={weixin}
      />
    </div>
  </Container>
);
