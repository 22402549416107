import React from 'react';
import { FormGroup, FormText, Input, Label } from 'reactstrap';

export default ({
  value,
  name,
  description,
  required = true,
  onChange,
  maxlength = 250
}) => (
  <FormGroup>
    <Label>
      {name} {required && '*'}
    </Label>
    <Input
      value={value}
      type={'textarea'}
      name={name}
      required={required}
      onChange={onChange}
      maxlength={maxlength}
    />
    <FormText>{description}</FormText>
  </FormGroup>
);
