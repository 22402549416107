import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { css } from 'glamor';

import Main from './routes/main';

export default () => (
  <Fragment>
    <Router>
      <Route path={'/'} component={Main} />
    </Router>
    <ToastContainer
      hideProgressBar={true}
      newestOnTop={true}
      toastClassName={css({
        borderRadius: '0.25rem'
      })}
    />
  </Fragment>
);
