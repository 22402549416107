import React from 'react';
import { Container } from 'reactstrap';
import BgDiv from './bgDiv';
import SingleSelector from './singleSelector';

export default ({
  bgImage,
  title,
  onChange,
  placeholderText,
  placeholderIcon,
  options = []
}) => (
  <BgDiv src={bgImage}>
    <div
      className={'py-3 py-md-5 py-xl-8'}
      style={styles.vaccineSelectionShade}
    >
      <Container className={'position-relative py-5'}>
        <h1 className={'text-light mx-sm-3 mb-md-4'}>{title}</h1>
        <div className={'bg-light p-3'}>
          <SingleSelector
            onChange={onChange}
            options={options}
            placeholderText={placeholderText}
            placeholderIcon={placeholderIcon}
          />
        </div>
      </Container>
    </div>
  </BgDiv>
);

const styles = {
  vaccineSelectionShade: {
    backgroundColor: 'rgba(0,0,0,0.4)'
  }
};
