import React from 'react';
import moment from 'moment';
import translate from '../../../../shared/translate';
import { Button } from 'reactstrap';
import { Mutation } from 'react-apollo';
import { Icon } from '../../../../components/iconRender';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import Loading from '../../../../components/loading';
import { errorParser } from '../../../../shared/errorParser';

const REMOVE_POST = gql`
  mutation eventReset($id: ID!) {
    eventReset(id: $id) {
      id
    }
  }
`;

export default ({ event: { slots = [], orderItem = {}, id = {} } = {} }) => {
  if (!slots[0]) return null;
  const {
    startedAt: startTime,
    venue: { name, address: { tel, email, lines = [] } = {}, images } = {}
  } = slots[0];
  const { order } = orderItem || {};
  const { billingAddress } = order || {};
  const { person, tel: billingTel } = billingAddress || {};
  const { startedThru: endTime } = slots[slots.length - 1];

  const is24HoursBefore = moment(startTime)
    .subtract(24, 'hour')
    .isAfter(moment());
  return (
    <div>
      <div className={'row d-flex align-items-center justify-content-between'}>
        <div className={'px-5 d-sm-block d-none'} />
        <div className={'px-4 d-sm-none d-block'} />
        <p className={'lead text-center m-0'}>
          {translate['reserve_successfully']}
        </p>
        {is24HoursBefore && (
          <Mutation mutation={REMOVE_POST}>
            {(mutate, { loading, data }) => {
              if (loading) return <Loading />;
              return (
                <div>
                  <Button
                    className={'btn btn-danger'}
                    onClick={async () => {
                      try {
                        await mutate({ variables: { id } });
                        window.location.reload();
                        toast.success(translate['cancel_successfully']);
                      } catch (e) {
                        toast.error(errorParser(e));
                      }
                    }}
                  >
                    {translate['reserve_cancel']}
                  </Button>
                </div>
              );
            }}
          </Mutation>
        )}
      </div>
      <div className={'media no-gutters'}>
        {/*<img*/}
        {/*  className={'col-3 col-md-2 d-none d-md-flex mr-3'}*/}
        {/*  src={'http://via.placeholder.com/180/e9ecef/000.png?text=%20'}*/}
        {/*/>*/}
        <div className={'media-body'}>
          <p className={'lead mb-0'}>{name}</p>

          <ul className={'fa-ul ml-4 mb-2'}>
            <li>
              <span className={'fa-li'}>
                <Icon icon={'faMapMarkedAlt'} />
              </span>
              <p className={'mb-0'}>{lines.join('\n') || 'dsjhdsj'}</p>
            </li>
            {person && (
              <li>
                <span className={'fa-li'}>
                  <Icon icon={'faUser'} />
                </span>
                預約人：{person}
              </li>
            )}
            {billingTel && (
              <li>
                <span className={'fa-li'}>
                  <Icon icon={'faPhone'} />
                </span>
                電話　：{billingTel}
              </li>
            )}
            <li>
              <span className={'fa-li'}>
                <Icon icon={'faClock'} />
              </span>
              <p className={'text-success mb-0'}>
                {moment(startTime).format(translate['moment_format_date'])}
                {moment(startTime).format('h:mm a')}
                <Icon icon={'faArrowRight'} className={'mx-2'} />
                {moment(endTime).format('h:mm a')}
              </p>
            </li>
            {tel && (
              <li>
                <span className={'fa-li'}>
                  <Icon icon={'faPhone'} />
                </span>
                {tel}
              </li>
            )}
            {email && (
              <li>
                <span className={'fa-li'}>
                  <Icon icon={'faEnvelope'} />
                </span>
                <a href={`mailto:${email}`}>{email}</a>
              </li>
            )}
          </ul>
          {!!images && images.length > 0 && (
            <img
              src={images[0]}
              className={'image-fluid object-fit-contain shadow p-3 mt-3'}
              alt={'Venue Preview'}
            />
          )}
        </div>
      </div>
    </div>
  );
};
