import React, { Fragment } from 'react';
import translate from '../../shared/translate';
import { Container, Button } from 'reactstrap';

export default ({ history }) => (
  <Fragment>
    <Container className={`container-fluid my-3 text-center`}>
      <h1 style={{ fontSize: 100 }}>404</h1>
      <p className={'lead'}>{translate['page_not_found']}</p>
      <Button
        outline={true}
        className={'mt-4'}
        onClick={() => {
          history.replace('/');
        }}
      >
        <span>{translate['back_to_home']}</span>
      </Button>
    </Container>
  </Fragment>
);
