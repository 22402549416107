import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import OrderItem from './_orderItem';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Loading from '../../../components/loading';
import translate from '../../../shared/translate';
import StyledPagination from '../../../components/styledPagination';
import { Helmet } from 'react-helmet';

const GET_QUERY = gql`
  query($status: OrderStatus, $skip: Int, $limit: Int) {
    me {
      id
      ordersCount(otherFilter: { status: $status })
      orders(
        otherFilter: { status: $status }
        offset: { skip: $skip, limit: $limit }
      ) {
        id
        status
        checkout {
          id
          attributes {
            key
            value
          }
        }
        total {
          amount
          locale
        }
        items {
          id
          description
          quantity
          unitPrice {
            amount
            locale
          }
          productVariant {
            id
            product {
              id
              name
            }
          }
        }
        attributes {
          key
          value
        }
        createdAt
      }
    }
  }
`;

export default class Orders extends Component {
  state = {
    skip: 0,
    limit: 5
  };

  onPaginationClick = async page => {
    let { limit } = this.state;
    this.setState({ skip: limit * (page - 1) });
  };

  render() {
    const { history } = this.props;
    const { skip, limit } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>
            {translate['menu_orders']} - {translate['meta.main.title']}
          </title>
        </Helmet>
        <Query
          query={GET_QUERY}
          variables={{
            skip,
            limit,
            status: 'COMPLETED'
          }}
          fetchPolicy={'network-only'}
        >
          {({ loading, data }) => {
            if (loading) return <Loading />;
            const { me: { orders = [], ordersCount = 0 } = {} } = data || {};
            let maxPage = Math.ceil(ordersCount / limit);

            if (ordersCount === 0)
              return (
                <div className={'text-center py-3'}>
                  <p className={'lead font-italic text-muted'}>
                    {translate['no_order_message']}
                  </p>
                  <Button
                    color={'primary'}
                    href={'/services'}
                    onClick={e => {
                      e.preventDefault();
                      history.push('/services');
                    }}
                  >
                    {translate['menu_service']}
                  </Button>
                </div>
              );
            else
              return (
                <Fragment>
                  {orders.map(
                    (
                      { createdAt, total, checkout: { attributes }, id, items },
                      i
                    ) => {
                      let buyerAttr = attributes.find(
                        attr => attr.key === 'buyer'
                      );
                      return (
                        <OrderItem
                          key={i}
                          createdAt={createdAt}
                          total_amount={total}
                          buyer={buyerAttr ? JSON.parse(buyerAttr.value) : {}}
                          orderId={id}
                          items={items}
                          history={history}
                        />
                      );
                    }
                  )}
                  {maxPage > 1 && (
                    <div className={'d-flex justify-content-center'}>
                      <StyledPagination
                        currentPage={skip / limit + 1}
                        maxPage={maxPage}
                        onClick={this.onPaginationClick}
                      />
                    </div>
                  )}
                </Fragment>
              );
          }}
        </Query>
      </Fragment>
    );
  }
}
