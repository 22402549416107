import React, { Fragment } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Loading from '../../components/loading';
import { Container } from 'reactstrap';
import CustomContent from './_customContent';
import CustomForm from './_customForm';
import translate from '../../shared/translate';
import { Helmet } from 'react-helmet';
import { auto2sc } from '../../shared/translate/tc2sc';
import Error from '../error';

const GET_QUERY = gql`
  query($href: String!, $groups: [String!]) {
    node: customPageByHref(otherFilter: { href: $href, groups: $groups }) {
      id
      name
      group
      body {
        name
        config
      }
    }
  }
`;

export default ({
  history,
  match: {
    params: { href }
  }
}) => (
  <Container className={`my-3`}>
    <Query
      query={GET_QUERY}
      variables={{ href, group: ['hc_promotion'] }}
      fetchPolicy={'cache-and-network'}
    >
      {({ loading, data }) => {
        if (loading) return <Loading />;
        try {
          const { node } = data || {};
          const { id, name, body = [] } = node;
          return (
            <Fragment>
              <Helmet>
                <title>
                  {auto2sc(name)} - {translate['meta.main.title']}
                </title>
              </Helmet>
              <CustomContent body={body[0]} />
              <div className={'col-md-6 offset-md-3 custom-container'}>
                <CustomForm
                  customPageId={id}
                  body={body[1]}
                  onSubmit={() => {
                    history.replace('/pages/promotion_success');
                  }}
                />
              </div>
            </Fragment>
          );
        } catch (e) {
          return <Error history={history} />;
        }
      }}
    </Query>
  </Container>
);
