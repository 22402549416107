import React from 'react';
import translate from '../../../shared/translate';
import { auto2sc } from '../../../shared/translate/tc2sc';
import NewsListItem from '../../../components/newsListItem';
import { Query } from 'react-apollo';
import { GET_QUERY } from './query';
import StyledTitle from '../../../components/styledTitle';
import { ListGroup } from 'reactstrap';
import LoadMore from './LoadMore';
import Loading from '../../../components/loading';

export default ({ history }) => {
  const limit = 8;
  return (
    <Query query={GET_QUERY} variables={{ skip: 0, limit }}>
      {({ loading, data: { node } = {}, fetchMore }) => {
        const { articles = [], count = 0 } = node || {};

        const hasMore = articles.length < count,
          nextSkip = articles.length;

        return (
          <div className={'h-100 d-flex flex-column'}>
            <StyledTitle type={'dark'} text={translate['latest_news']} />
            <ListGroup
              className={'rounded-0 overflow-auto'}
              style={{ maxHeight: 450 }}
            >
              {articles
                .map(article => ({
                  ...article,
                  href: auto2sc(article.href),
                  name: auto2sc(article.name)
                }))
                .map((props, i) => {
                  const { href } = props;
                  return (
                    <NewsListItem
                      key={i}
                      {...props}
                      onClick={() => {
                        history.push(`articles/${href}`);
                      }}
                    />
                  );
                })}
              {loading ? (
                <Loading />
              ) : (
                <LoadMore
                  onObserved={() => {
                    if (!hasMore) return;
                    fetchMore({
                      variables: { skip: nextSkip, limit },
                      updateQuery: (prevResult, { fetchMoreResult }) => {
                        const newArticles = getArticles(fetchMoreResult),
                          oldArticles = getArticles(prevResult);

                        return {
                          node: {
                            ...(fetchMoreResult.node || {}),
                            articles: [...oldArticles, ...newArticles]
                          }
                        };

                        function getArticles({ node } = {}) {
                          const { articles = [] } = node || {};
                          return articles;
                        }
                      }
                    });
                  }}
                />
              )}
            </ListGroup>
          </div>
        );
      }}
    </Query>
  );
};
