import StripePaymentWidget from './_stripePaymentWidget';

const { REACT_APP_STRIPE_KEY } = process.env;

export default class StripeConnectCustomPaymentWidget extends StripePaymentWidget {
  state = {
    stripeAPIKey: REACT_APP_STRIPE_KEY,
    isSubmitting: false
  };
}
StripeConnectCustomPaymentWidget.propTypes = {
  ...StripePaymentWidget.propTypes
};
