import React from 'react';
import SlickSliderWrapper from './slickSliderWrapper';

//TODO query shopByHostname

const Carousel = ({ config }) => {
  const { images = [] } = config;
  return (
    <div className='embed-responsive embed-responsive-16by9'>
      <SlickSliderWrapper images={images} />
    </div>
  );
};

export const ComponentRender = ({ tag, ...props }) => {
  const components = {
    banner: Carousel
  };
  const TagName = components[tag];
  if(!TagName) return null;
  return <TagName {...props} />;
};
