import React, { Component } from 'react';

export default class LoadMore extends Component {
  constructor(props) {
    super(props);
    this.trigger = React.createRef();
  }
  onObserved = () => {
    const { onObserved } = this.props;
    onObserved();
  };
  componentDidMount = () => {
    if (!!this.trigger && !!this.trigger.current) {
      const handleObserver = entities => {
        const target = entities[0];
        if (target.isIntersecting) {
          console.log('isIntersecting');
          this.onObserved();
        }
      };
      const observer = new IntersectionObserver(handleObserver, {
        root: null,
        rootMargin: '20px',
        threshold: 1.0
      });
      observer.observe(this.trigger.current);
    }
  };

  render() {
    return <div ref={this.trigger} />;
  }
}
