import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Rect from '../../components/rect';
import VaccineSelection from '../../components/styledSingleSelection';
import StyledList from '../../components/styledList';
import NewsListItem from '../../components/newsListItem';
import Banner from './_banner';
import Loading from '../../components/loading';
import translate from '../../shared/translate';
import { auto2sc } from '../../shared/translate/tc2sc';
import InfinityArticles from './InfinityArticles';

const GET_SHOP = gql`
  query {
    node: shopByHostname {
      id
      homepage: customPages(otherFilter: { href: "homepage" }) {
        id
        name
        href
        group
        body {
          name
          config
        }
      }
      services(otherFilter: { active: true, display: true }) {
        id
        name
        items {
          productVariant {
            id
            price
          }
        }
        price
      }
    }
  }
`;

export default class Main extends Component {
  onServiceClick = ({ value }) => {
    const { history } = this.props;
    history.push(`/checkout/${value}`);
  };

  render() {
    const { history } = this.props;

    return (
      <Query query={GET_SHOP}>
        {({ loading, data }) => {
          if (loading) return <Loading />;
          const { node } = data || {};
          const { services = [], homepage: [_first] = [{}] } = node || {};

          function compare(key) {
            return (a, b) => {
              if (a[key] < b[key]) return -1;
              if (a[key] > b[key]) return 1;
              return 0;
            };
          }

          let sortObj = services.sort(compare('name'));
          console.log(sortObj);
          return (
            <Fragment>
              <Banner body={_first ? _first.body : undefined} />
              <Col>
                <Row>
                  <div className={'flex-fill'}>
                    <Rect
                      onClick={() => {
                        history.push('/services');
                      }}
                      href={'/services'}
                      backgroundColor={'#5fc8f1'}
                      text={translate['vaccine_offer']}
                      icon={'faEyeDropper'}
                    />
                  </div>
                  <div className={'flex-fill'}>
                    <Rect
                      onClick={() => {
                        history.push('/profile');
                      }}
                      href={'/profile'}
                      backgroundColor={'#3fa5f1'}
                      text={translate['menu_member']}
                      icon={'faUserAlt'}
                    />
                  </div>
                  <div className={'flex-fill'}>
                    <Rect
                      onClick={() => {
                        history.push('/pages/plans');
                      }}
                      href={'/pages/plans'}
                      backgroundColor={'#0071b8'}
                      text={translate['checkup_plan']}
                      icon={'faHeartbeat'}
                    />
                  </div>
                  <div className={'flex-fill'}>
                    <Rect
                      onClick={() => {
                        history.push('/pages/services');
                      }}
                      href={'/pages/services'}
                      backgroundColor={'#0054a4'}
                      text={translate['professional_service']}
                      icon={'faStethoscope'}
                    />
                  </div>
                  <div className={'flex-fill'}>
                    <Rect
                      onClick={() => {
                        history.push('/pages/networks');
                      }}
                      href={'/pages/networks'}
                      backgroundColor={'#66dbff'}
                      text={translate['premium_network']}
                      icon={'faShareAlt'}
                    />
                  </div>
                </Row>
              </Col>
              <VaccineSelection
                bgImage={require('../../assets/about-section-01.png')}
                placeholderText={translate['choose_vaccine']}
                placeholderIcon={'faEyeDropper'}
                title={translate['medicine_service_in_hk']}
                options={services.map(({ id, name }) => ({
                  label: auto2sc(name),
                  value: id
                }))}
                onChange={this.onServiceClick}
              />
              <div className={'py-3 py-md-5'}>
                <Container>
                  <Row>
                    <Col className={'col-12 col-sm'}>
                      <StyledList
                        onClick={this.onServiceClick}
                        title={translate['vaccine_guide']}
                        list={sortObj.map(
                          ({ id, name, price, items = [] }) => ({
                            title: auto2sc(name),
                            value: id,
                            href: `/checkout/${id}`,
                            remark: `${translate['n_servers'].replace(
                              '{n}',
                              items.length
                            )}\n${price.toLocaleString(undefined, {
                              style: 'currency',
                              currency: 'HKD'
                            })}`
                          })
                        )}
                      />
                    </Col>
                    <Col className={'col-12 col-sm mt-3 mt-sm-0'}>
                      <InfinityArticles history={history} />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Fragment>
          );
        }}
      </Query>
    );
  }
}
