import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Loading from '../../components/loading';
import Error from '../error';
import translate from '../../shared/translate';
import { auto2sc, sc2tc } from '../../shared/translate/tc2sc';
import { Helmet } from 'react-helmet';

const GET_CUSTOM_PAGE = gql(`
  query ($href: String!, $groups: [String!]) {
    node:customPageByHref(otherFilter: { href: $href, groups: $groups }) {
      id
      name
      body {
        name
        config
      }
    }
  }
`);

export default ({
  history,
  match: {
    params: { href, group = 'articles' }
  }
}) => {
  let { _language } = translate;
  let searchHref = _language === 'zh-CN' ? sc2tc(href) : href;

  return (
    <Query
      query={GET_CUSTOM_PAGE}
      variables={{ href: searchHref, groups: [group] }}
    >
      {({ loading, data = {} }) => {
        if (loading) return <Loading />;
        try {
          let {
            node: {
              name,
              body: [{ config = '{}' }]
            }
          } = data;

          config = auto2sc(config);
          let { content = '' } = JSON.parse(config);

          return (
            <Fragment>
              <Helmet>
                <title>
                  {auto2sc(name)} - {translate['meta.main.title']}
                </title>
              </Helmet>
              <Container
                className={`container-fluid my-3 custom-container`}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Fragment>
          );
        } catch (e) {
          return <Error history={history} />;
        }
      }}
    </Query>
  );
};
