import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import StripePaymentForm from '../../components/stripePaymentForm';
import translate from '../../shared/translate';
import { toast } from 'react-toastify';
import { errorParser } from '../../shared/errorParser';
import { StripeProvider } from 'react-stripe-elements';
const { REACT_APP_STRIPE_KEY } = process.env;

export default class StripePaymentWidget extends Component {
  state = {
    stripeAPIKey: REACT_APP_STRIPE_KEY,
    isSubmitting: false
  };
  isSubmitting = false;

  submit = async stripe => {
    let {
      addressData: { person },
      onSuccess
    } = this.props;

    if (this.isSubmitting) return;

    try {
      this.isSubmitting = true;
      this.setState({ isSubmitting: true });
      const { error, token = {} } = await stripe.createToken({
        name: person
      });
      if (error) throw new Error(error.code);
      onSuccess && (await onSuccess(token));
    } catch (e) {
      toast.error(errorParser(e, false));
    } finally {
      this.isSubmitting = false;
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { isSubmitting, stripeAPIKey } = this.state;
    return (
      <Fragment>
        <h4>{translate['please_input_credit_card_info']}</h4>
        <hr />
        <StripeProvider apiKey={stripeAPIKey}>
          <StripePaymentForm
            isSubmitting={isSubmitting}
            submitText={translate['submit']}
            onSubmit={this.submit}
          />
        </StripeProvider>
      </Fragment>
    );
  }
}
StripePaymentWidget.propTypes = {
  addressData: PropTypes.object,
  onSuccess: PropTypes.func
};
