import gql from 'graphql-tag';
import { client } from '../../../shared/apollo';
import { errorParser } from '../../../shared/errorParser';

const checkoutServiceSet = async ({ checkoutId, input }) => {
  const {
    data: { result }
  } = await client.mutate({
    mutation: gql`
      mutation($id: ID!, $input: CheckoutServiceInput!) {
        result: checkoutServiceSet(id: $id, input: $input) {
          id
          items {
            id
            productVariant {
              id
              product {
                id
                name
                images
              }
              attributes {
                key
                value
              }
              price
              quantity
            }
            quantity
          }
          shop {
            id
          }
          attributes {
            key
            value
          }
          shippingFee
          taxFee
          discountedPrice
          updatedAt
        }
      }
    `,
    variables: {
      id: checkoutId,
      input
    }
  });
  return result;
};

export default checkoutServiceSet;
