import gql from 'graphql-tag';

export const GET_ME = gql`
  query($serviceId: ID) {
    shopByHostname {
      id
      credentials(otherFilter: { types: [PAYMENT] }) {
        active
        platform
        type
      }
    }
    service: node(id: $serviceId) {
      id
      ... on Service {
        id
        name
        description
        price
      }
    }
    me {
      id
      credentials {
        id
        identity
      }
      groups {
        name
      }
      addresses {
        id
        person
        tel
        email
        lines
        country
      }
    }
  }
`;

export const CHECK_COUPON_CODE = gql`
  query($handle: String) {
    node(handle: $handle) {
      id
      __typename
      ... on Coupon {
        shop {
          id
        }
        amountBeforeDiscount
        discount
        discountType
      }
    }
    shopByHostname {
      id
    }
  }
`;
