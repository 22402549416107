import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Icon } from '../../../components/iconRender';
import PropTypes from 'prop-types';
import translate from '../../../shared/translate';

export default class AddressesList extends Component {
  render() {
    let { addresses, selected, onSelect } = this.props;
    return (
      <ListGroup>
        {addresses.map(({ person, country, lines }, i) => (
          <ListGroupItem
            active={selected === i}
            key={i}
            onClick={() => onSelect(i)}
          >
            <b className='mr-3'>{person}</b>
            <span>
              {translate[country]} {lines.join(' ')}
            </span>
          </ListGroupItem>
        ))}
        <ListGroupItem
          active={selected === addresses.length}
          onClick={() => onSelect(addresses.length)}
        >
          <Icon icon={'faPlus'} />
          <span className={'ml-2'}>{translate['create_person']}</span>
        </ListGroupItem>
      </ListGroup>
    );
  }
}
AddressesList.propTypes = {
  addresses: PropTypes.array,
  selected: PropTypes.number,
  onSelect: PropTypes.func
};
