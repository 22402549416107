import React from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker
} from 'react-google-maps';

const { REACT_APP_GOOGLE_API_KEY = '' } = process.env;

const GoogleMapWrapper = withScriptjs(
  withGoogleMap(
    ({ defaultZoom = 20, center = { lat: 22.2903368, lng: 114.1952975 } }) => (
      <GoogleMap defaultZoom={defaultZoom} defaultCenter={center}>
        <Marker position={center} />
      </GoogleMap>
    )
  )
);
GoogleMapWrapper.defaultProps = {
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
  loadingElement: <div style={{ height: '100%', width: '100%' }} />,
  containerElement: <div style={{ height: '100%', width: '100%' }} />,
  mapElement: <div style={{ height: '100%', width: '100%' }} />
};

export default GoogleMapWrapper;
