import React, { Fragment } from 'react';
import {
  Card,
  CardBody,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Button,
  FormGroup
} from 'reactstrap';
import SingleSelector from '../../components/singleSelector';
import translate from '../../shared/translate';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { errorParser } from '../../shared/errorParser';
import { CHECK_COUPON_CODE } from './query';
import { client } from '../../shared/apollo';
import { Icon } from '../../components/iconRender';

export default ({ addresses, onAddressChange, onCouponChange, disabled }) => {
  return (
    <Card className={'my-3'}>
      <CardBody>
        <FormGroup>
          <h6>{translate['person']} *</h6>
          <SingleSelector
            onChange={onAddressChange}
            isDisabled={disabled}
            placeholderText={translate['please_select_person']}
            options={addresses
              .map(address => ({
                raw: { ...address, __typename: undefined },
                label: (
                  <Fragment>
                    <b>{address.person}</b> {address.tel}
                  </Fragment>
                ),
                value: address.id
              }))
              .concat({
                raw: null,
                label: translate['create_person'],
                value: 0
              })}
          />
        </FormGroup>
        <Formik
          onSubmit={async ({ coupon }, { setSubmitting, setFieldValue }) => {
            try {
              const {
                data: { node, shopByHostname } = {}
              } = await client.query({
                query: CHECK_COUPON_CODE,
                variables: {
                  handle: coupon
                }
              });
              const { __typename, shop } = node || {},
                { id: shopId } = shop || {};
              const { id: _shopId } = shopByHostname || {};
              if (__typename === 'Coupon' && shopId === _shopId) {
                onCouponChange(node);
                setFieldValue('confirmed', true);
                toast.success(translate['use_coupon']);
              } else throw new Error('Error: Invalid Coupon');
            } catch (e) {
              toast.error(errorParser(e));
            } finally {
              setSubmitting(false);
            }
          }}
          initialValues={{ coupon: '', confirmed: false }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            handleChange,
            isSubmitting
          }) => (
            <form
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(e);
              }}
            >
              <FormGroup className={'mb-0'}>
                <h6>{translate['coupon']}</h6>
                {values.confirmed ? (
                  <Row noGutters={true} className={'align-items-center'}>
                    <Icon icon={'faTag'} />
                    <span className={'ml-2'}>{values.coupon}</span>
                    <Button
                      type={'button'}
                      color={'link'}
                      className={'text-danger'}
                      disabled={disabled}
                      onClick={async () => {
                        setFieldValue('confirmed', false);
                        onCouponChange(undefined);
                      }}
                    >
                      <Icon icon={'faTimes'} />
                    </Button>
                  </Row>
                ) : (
                  <Row>
                    <Col sm={12} md={6} lg={4}>
                      <InputGroup>
                        <Input
                          required
                          name={'coupon'}
                          onChange={handleChange}
                          disabled={disabled || isSubmitting}
                        />
                        <InputGroupAddon addonType={'append'}>
                          <Button
                            type={'submit'}
                            color={'secondary'}
                            outline={true}
                            style={{ zIndex: 0 }}
                            disabled={disabled || isSubmitting}
                          >
                            {translate['confirm']}
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                )}
              </FormGroup>
            </form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};
